import React, { useState, useEffect } from 'react';
import { NOTIFICATION_PERMISSIONS } from '../../util/ConstantsService';
import { Button, notification, Typography } from 'antd';
import { requestFCMToken } from '../../util/FirebaseNotificationService';

const Secrets = () => {
  const [fcmToken, setToken] = useState(null);
  const [notifStatus, setNotifStatus] = useState(Notification.permission);
  // useEffect(() => {});
  const getToken = () => {
    if (notifStatus !== NOTIFICATION_PERMISSIONS.GRANTED) {
      return notification.error({
        message: 'Pls allow notifications to get token.',
      });
    }
    requestFCMToken()
      .then(token => {
        setToken(token);
        notification.success({ message: 'Got token.' });
      })
      .catch(e => {
        console.log('Err: ', e);
        notification.error({ message: 'Fail to get token.' });
      });
  };
  const allowNotifs = () => {
    Notification.requestPermission().then(result => {
      setNotifStatus(result);
    });
  };
  return (
    <>
      <Typography.Title level={2}>Secrets</Typography.Title>
      <div>
        FCM Token:{' '}
        <Typography.Text copyable={!!fcmToken}>{fcmToken}</Typography.Text>{' '}
        {!fcmToken && (
          <Button type={'link'} onClick={getToken}>
            Get Token
          </Button>
        )}
      </div>
      <div>
        Notification Status: {notifStatus}
        {notifStatus === NOTIFICATION_PERMISSIONS.DEFAULT && (
          <Button type={'link'} onClick={allowNotifs}>
            Allow Notification
          </Button>
        )}
      </div>
    </>
  );
};

export default Secrets;
