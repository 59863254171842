import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLogout } from '../../redux/feature/login/login.action';

const Logout = props => {
  return (
    <Menu style={{ paddingBottom: 120 }}>
      <Menu.Item key="logout-menu-item">
        <Link
          to="/"
          // onClick={() => {
          //   props.getLogout();
          // }}
        >
          <LogoutOutlined />
          <span className="nav-text">Logout</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const mapStateToProps = ({ initialReducer }) => ({
  isLoggedIn: initialReducer.isLoggedIn,
});

const mapDispatchToProps = {
  getLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
