import { call, put, delay, all } from 'redux-saga/effects';
import {
  SET_ALL_PRODUCTS,
  SET_INIT_PRODUCT_DETAILS,
  SET_LOADING_PRODUCTS,
  SET_LOADING,
  SET_INIT_GENERATE_QR_DATA,
  SET_SKU_FOR_SKID,
} from './product.action';
import {
  getCategoriesForProductApi,
  getAttributesBySubcategoryForProductApi,
  postProductApi,
  putProductApi,
  getAllProductsApi,
  getProductByIdApi,
  getProductPriceDetailsApi,
  searchSkuApi,
  getAllPresentAndFuturePresaleApi,
} from './product.api';
import { notification } from 'antd';
import history from '../../history';
import {
  getCategory,
  getCutType,
  getProduct,
  getProductClass,
  getSize,
  getSubCategory,
} from '../master-data/master.api';

export function* getInitProductDetailSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_PRODUCTS, data: true });
    const { productId, subCategoryId } = data;
    const promises = [];
    if (subCategoryId) {
      promises.push(
        call(getAttributesBySubcategoryForProductApi, subCategoryId)
      );
    } else {
      promises.push(call(getCategoriesForProductApi));
    }
    if (productId) promises.push(call(getProductByIdApi, productId));

    // promises.push(delay(500));
    const [masterDataResp, productResp] = yield all(promises);
    const responseData = {};
    if (subCategoryId) {
      responseData['attributes'] = masterDataResp.data;
    } else {
      responseData['category'] = masterDataResp.data.categories;
      responseData['cancellationStates'] =
        masterDataResp.data.cancellationStates;
    }
    if (productId) {
      responseData['productDetail'] = productResp.data;
      if (productResp.data.subCategoryId) {
        const attrs = yield call(
          getAttributesBySubcategoryForProductApi,
          productResp.data.subCategoryId
        );
        responseData['attributes'] = attrs.data;
      }
    }
    yield put({
      type: SET_INIT_PRODUCT_DETAILS,
      data: responseData,
    });
  } catch (e) {
    console.log('Err@ getInitProductDetailSaga: ', e);
  }
}

export function* upsertProductSaga({ data }) {
  try {
    // const product = {"productName":"s","status":false,"categoryId":3,"description":"asd","canPresale":false,"isCancellable":false,"attributes":[1,2,3],"costPrice":221,"retailPrice":221,"onSale":false,"isPresale":false,"optionsValues-1":3,"optionsValues-2":[5,6,7],"optionsValues-3":10,"attributeValues":[{"productAtrId":1,"attributeOptionId":3},{"productAtrId":2,"attributeOptionId":5},{"productAtrId":2,"attributeOptionId":6},{"productAtrId":2,"attributeOptionId":7},{"productAtrId":3,"attributeOptionId":10}],"skus":[{"costPrice":221,"retailPrice":221,"onSale":false,"isPresale":false}],"productType":"dummy product type"};
    let api = postProductApi;
    let message = 'Product added successfully.';
    if (data && data.id) {
      api = putProductApi;
      message = 'Product updated successfully.';
    }
    data.productType = 'dummy product type';
    console.log('Post Data: ', JSON.stringify(data));
    yield call(api, data);

    history.push('/products');
    notification.success({ message });
  } catch (e) {
    console.log('Err@ getLogoutSaga: ', e);
  }
}

// export function* getAllProductsSaga() {
// try {
//   yield put({ type: SET_LOADING_PRODUCTS, data: true });
//   // yield delay(2000);
//   const { data } = yield call(getAllProductsApi);
//   yield put({ type: SET_ALL_PRODUCTS, data });
// } catch (e) {
//   console.log('Err@ getAllProductsSaga: ', e);
// }
// }

export function getAllProductsSaga() {
  return getAllProductsApi();
}

export function getProductPriceDetailsSaga({ data }) {
  return getProductPriceDetailsApi(data);
}

export function getAllPresentAndFuturePresaleSaga({ data }) {
  return getAllPresentAndFuturePresaleApi(data);

  // export function updateProductPriceDetailsSaga({ data }) {
  //   return updateProductPriceDetailsApi(data);
}

export function* getInitGenerateQRDataSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const promises = [
      call(getCategory),
      call(getSubCategory),
      call(getProductClass),
      call(getProduct),
      call(getCutType),
      call(getSize),
    ];

    const [
      { data: category },
      { data: subCategory },
      { data: productClass },
      { data: product },
      { data: cutType },
      { data: size },
    ] = yield all(promises);
    const data = {
      category,
      subCategory,
      productClass,
      product,
      cutType,
      size,
    };
    yield put({ type: SET_INIT_GENERATE_QR_DATA, data });
  } catch (e) {
    console.log('Err@ getInitGenerateQRDataSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message: 'Error Fetching data.' });
  }
}

export function* searchSkuSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: skus } = yield call(searchSkuApi, data);
    yield put({ type: SET_SKU_FOR_SKID, data: skus });
    return Promise.resolve(skus);
  } catch (e) {
    console.log('Err@ searchSkuSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    notification.error({ message: 'Error while searching SKUs.' });
    return Promise.reject(e);
  }
}
