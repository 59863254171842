import React from 'react';
import {
  Button,
  Card,
  Descriptions,
  notification,
  Spin,
  Typography,
} from 'antd';
import {
  getActiveTrips,
  postAcceptTripApi,
} from '../../redux/feature/delivery-box/delivery-box.api';
import './delivery-box.scss';
import { dummyActiveTrips } from '../../util/ConstantsService';

const ActiveTrip = () => {
  const [activeTrips, setActiveTrips] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  // const activeTrips = {"orderId":30453,"customerId":18787,"orderNo":"20230130RFH8","slotTime":"30 Jan 2023 4 PM-6 PM","paymentMode":"HBW","amount":80.0,"deliveryAddress":"805, 10, Municipal Colony, Byculla West, Byculla, Mumbai, Maharashtra 400011, India, ","customerName":"Noman Ansari","mobileNumber":"9769140533","adcCode":"MUM04","adcAddress":"Shop No.2, Ground Floor, 20, B I T Chawl, R B Chandoorkar Marg, Jacob Circle, Agripada, Mumbai, Maharashtra 400011","orderDate":"2023-01-30T10:39:54.985+0000","orderCurrentStatus":"ORDER_MARK_LOGISTIC_FAILED","orderStatus":["ORDER_LOGISTICS_DELIVERED","ORDER_LOGISTICS_PICKED_UP","ORDER_LOGISTICS_DELIVERY_FAILED"],"orderPlatform":"halalbox","pgId":0};
  React.useEffect(() => {
    // getActiveTrips().then(({ data }) => {
    Promise.resolve({ data: dummyActiveTrips })
      .then(({ data }) => {
        setActiveTrips(data);
        setLoading(false);
      })
      .catch(e => {
        console.log('Err @getActiveTrips: ', e);
        const message = e.message?.description || 'Fail to get trips.';
        notification.error({ message });
        setLoading(false);
      });
  }, []);
  const acceptOrder = orderId => {
    const payload = { orderId, riderId: 31 };
    setLoading(true);
    postAcceptTripApi(payload)
      .then(resp => {
        console.log('resp: ', resp);
        // notification.success({ message: })
        setLoading(false);
      })
      .catch(e => {
        console.log('Err @acceptOrder: ', e);
        setLoading(false);
      });
  };
  return (
    <>
      <Typography.Title level={2}>Active Trips</Typography.Title>
      <Spin spinning={loading}>
        {activeTrips?.length > 0 &&
          activeTrips.map((trip, index) => (
            <div className="active-trip" key={index}>
              <Card
                size="small"
                actions={[
                  <Button type="link" onClick={() => acceptOrder(trip.orderId)}>
                    Accept
                  </Button>,
                  <Button type="link">Reject</Button>,
                ]}
              >
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="Pickup From">
                    {trip.adcAddress}
                  </Descriptions.Item>
                  <Descriptions.Item label="Drop At">
                    {trip.deliveryAddress}
                  </Descriptions.Item>
                  <Descriptions.Item label="Deliver By">
                    {trip.slotTime}
                  </Descriptions.Item>
                  <Descriptions.Item label="ADC">
                    {trip.adcCode}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </div>
          ))}
      </Spin>
    </>
  );
};

export default ActiveTrip;
