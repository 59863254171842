import request from '../../../util/request';

export const getCustomersApi = ({ page = 0, size = 100 }) =>
  request.get(`/crm/customer?page=${page}&size=${size}`);

export const postOrderForCustomerApi = data =>
  request.post('/crm/ccaLoginOnCustomerBehalf', data);

export const getCustomerByIdApi = customerId =>
  request.get(`/crm/customer/${customerId}`);

export const postCustomerApi = data =>
  request.post('/crm/saveCustomerDetails', data);

export const putCustomerApi = data =>
  request.put('/crm/updateCustomerDetails', data);

export const getAddressByCustomerIdApi = customerId =>
  request.get(`/crm/fetchCustomerAddressById?customerId=${customerId}`);

export const postAddressApi = data =>
  request.post('/saveCustomerAddress', data);

export const putAddressApi = data =>
  request.put('/updateCustomerAddress', data);

export const searchCustomersApi = data =>
  request.post('/crm/customerSearch', data);
