import request from '../../../util/request';

export const postAuthenticateApi = data =>
  request.post('/crm/crmUserLogin', data);
export const postRegisterApi = data =>
  request.post('/crm/registerHbUser', data);

export const logoutApi = () => request.post('/crm/crmUserLogout');

export const sendOTPApi = data => request.post('/delivery/sendOTP', data);

export const verifyLoginOTPApi = data =>
  request.post('/delivery/verifyOTP', data);

export const signUpApi = data => request.post('/delivery/signUp', data);
