import React from 'react';
import {
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Table,
} from 'antd';
import {
  getAuthoritiesApi,
  getMenusApi,
  getMenuSectionsApi,
  postAuthorityApi,
  putAuthorityApi,
} from '../../redux/feature/auth/auth.api';
import AuthService from '../../util/AuthService';
import { authSet } from '../../feature/auth/auth.routes';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { isAuthorisedUser } = AuthService;

const AuthCodeList = props => {
  const [loading, setLoading] = React.useState(true);
  const [auth, setAuth] = React.useState(null);
  const [menuSections, setMenuSections] = React.useState(null);
  const [filteredMenuSections, setFilteredMenuSections] = React.useState(null);
  const [menus, setMenus] = React.useState(null);
  const [openPanes, setOpenPanes] = React.useState(null);
  const [showEditModal, setEditModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(true);
  const [formRef] = Form.useForm();
  const columns = [
    {
      title: 'Menu',
      dataIndex: 'menu',
    },
    {
      title: 'Section',
      dataIndex: 'section',
    },
    {
      title: 'Auth Code',
      dataIndex: 'authorityCode',
    },
    {
      title: 'Actions',
      render: (text, record) => {
        return (
          <Space>
            {isAuthorisedUser(authSet.EDIT_AUTH_CODES) && (
              <Button
                type="link"
                onClick={() => {
                  setIsEdit(true);
                  formRef.resetFields();
                  formRef.setFieldsValue(record);
                  const filteredSections = menuSections.filter(
                    ms => ms.menuId === record.menuId
                  );
                  setFilteredMenuSections(filteredSections);
                  setEditModal(true);
                }}
              >
                Edit Auth Code Mapping
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
  const getAuthCodes = () => {
    setLoading(true);
    return getAuthoritiesApi().then(resp => {
      const panes = [];
      const tabularData = resp.data.map(menu => {
        const sections = [];
        panes.push(menu.menuId);
        menu.menuSectionList.map(section => {
          section.menuAuthorityCodes.map(code => {
            const row = {
              menuId: menu.menuId,
              menu: menu.menu,
              menuSectionId: section.menuSectionId,
              section: section.menuSection,
              authorityId: code.authorityId,
              authorityCode: code.authorityCode,
            };
            sections.push(row);
          });
        });
        return {
          menuId: menu.menuId,
          menu: menu.menu,
          sections,
        };
      });
      setAuth(tabularData);
      setOpenPanes(panes);
      setLoading(false);
    });
  };
  React.useEffect(() => {
    setLoading(true);
    Promise.all([getMenusApi(), getMenuSectionsApi(), getAuthCodes()])
      .then(([menuResp, sectionResp]) => {
        setMenus(menuResp.data);
        setMenuSections(sectionResp.data);
      })
      .catch(e => {
        console.log('Err @AuthCodeList: ', e);
        setLoading(false);
        notification.error({ message: 'Error loading auth codes. ' });
      });
  }, []);
  const saveAuthCode = values => {
    setLoading(true);
    setOpenPanes(null);
    let api = postAuthorityApi;
    let { menuId, menuSectionId, authCodes } = values;
    let promises = [];
    if (values.authorityId) {
      api = putAuthorityApi;
      promises.push(api({ authList: [values] }));
    } else {
      promises = authCodes.map(ac =>
        api({ menuId, menuSectionId, authorityCode: ac })
      );
    }
    Promise.allSettled(promises).then(resp => {
      const failedApis = resp.filter(p => p.status === 'rejected');
      let message = `Authority Codes ${isEdit ? 'updated.' : 'created.'}`;
      if (failedApis.length) {
        if (failedApis.length === promises.length) {
          message = `All authority codes failed.`;
        } else {
          message = `${failedApis.length} auth codes failed.`;
        }
        console.log('Err @saveAuthCode: ', failedApis);
        notification.error({ message });
      } else {
        getAuthCodes();
        notification.success({ message });
        setEditModal(false);
      }
      setLoading(false);
    });

    // api(reqBody)
    //   .then(resp => {
    //     setLoading(false);
    //     getAuthCodes();
    //     notification.success({
    //       message: `Authority Code ${isEdit ? 'updated.' : 'created.'}`,
    //     });
    //     setEditModal(false);
    //   })
    //   .catch(e => {
    //     console.log('Err @saveAuthCode: ', e);
    //     setLoading(false);
    //     notification.error({ message: 'Error saving authority code.' });
    //   });
  };
  return (
    <div>
      <Spin spinning={loading}>
        {isAuthorisedUser(authSet.ADD_AUTH_CODES) && (
          <Button
            type="primary"
            style={{ margin: '16px 0' }}
            onClick={() => {
              setIsEdit(false);
              formRef.resetFields();
              formRef.setFieldsValue({ authCodes: [''] });
              setEditModal(true);
            }}
          >
            Add new authority code mapping
          </Button>
        )}
        {openPanes && (
          <Collapse defaultActiveKey={openPanes}>
            {auth &&
              auth.map(a => {
                return (
                  <Collapse.Panel key={a.menuId} header={a.menu}>
                    <Table
                      columns={columns}
                      dataSource={a.sections}
                      loading={loading}
                      rowKey="authCodeId"
                      scroll={{ x: 800 }}
                      bordered
                      size="small"
                    />
                  </Collapse.Panel>
                );
              })}
          </Collapse>
        )}
      </Spin>
      <Modal
        destroyOnClose={true}
        onOk={() => formRef.submit()}
        onCancel={() => {
          setEditModal(false);
        }}
        visible={showEditModal}
        title={isEdit ? 'Edit Auth Code Mapping' : 'Add New Auth Code Mapping'}
        confirmLoading={loading}
        okText={isEdit ? 'Update' : 'Add'}
      >
        <Form form={formRef} layout="vertical" onFinish={saveAuthCode}>
          <Form.Item noStyle name="authorityId">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="menuId" label="Menu:" rules={[{ required: true }]}>
            <Select
              onChange={menuId => {
                const filteredSections = menuSections.filter(
                  ms => ms.menuId === menuId
                );
                setFilteredMenuSections(filteredSections);
              }}
            >
              {menus?.map(menu => (
                <Select.Option value={menu.menuId}>
                  {menu.menuCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="menuSectionId"
            label="Menu Section:"
            rules={[{ required: true }]}
          >
            <Select>
              {filteredMenuSections?.map(section => (
                <Select.Option value={section.menuSectionId}>
                  {section.sectionCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!isEdit && (
            <Form.List
              name="authCodes"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error('At least 1 auth code needed')
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      label={'Auth Code'}
                      required={true}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              'Please add auth code or delete this field.',
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="Auth Code"
                          style={{ width: index !== 0 ? '80%' : '' }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  {!isEdit && (
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{
                          width: '60%',
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add More Auth Code
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          )}
          {isEdit && (
            <Form.Item
              name="authorityCode"
              label="Authority Code:"
              rules={[{ required: true }]}
            >
              <Input placeholder="Auth code" />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default AuthCodeList;
