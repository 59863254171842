import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import request from '../../util/request';

export const NewTripAlert = () => {
  const [hasNotifAccess, setNotifAccess] = useState(null);
  useEffect(() => {
    request
      .get('/delivery/ping')
      .then(resp => {
        console.log('Launch Resp: ', resp);
      })
      .catch(e => {
        console.log('Err: ', e);
      });
    Notification.requestPermission().then(result => {
      console.log(result);
      setNotifAccess(result);
    });
  }, []);
  const requestForPermission = () => {
    Notification.requestPermission().then(result => {
      setNotifAccess(result);
    });
  };
  return (
    <>
      <Typography.Title level={2}>Orders</Typography.Title>
      {hasNotifAccess === 'denied' && (
        <Typography.Title level={3}>
          Notification Permission Is Denied
        </Typography.Title>
      )}
      {hasNotifAccess === 'granted' && (
        <Typography.Title level={3}>Notifications Are Allowed</Typography.Title>
      )}
      {hasNotifAccess === 'default' && (
        <div>
          <Typography.Title level={3}>
            Please allow notification to access trips
          </Typography.Title>
          <Button type={'default'} onClick={requestForPermission}>
            Allow Notification
          </Button>
        </div>
      )}
    </>
  );
};
