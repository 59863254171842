import request from '../../../util/request';

export const getOrdersApi = ({ page = 0, size = 100, orderType }) =>
  request.get(
    `/crm/orders?page=${page}&size=${size}${
      orderType ? `&orderType=${orderType}` : ''
    }`
  );

export const getPresaleOrdersApi = ({ page = 0, size = 100, orderType }) =>
  request.get(
    `/crm/orders/presale?page=${page}&size=${size}${
      orderType ? `&orderType=${orderType}` : ''
    }`
  );

export const searchOrdersApi = data => request.post('/crm/orderSearch', data);

export const getOrderByNoApi = ({ orderNo }) =>
  request.get(`/crm/order/${orderNo}`);

export const getPrimeMembershipByOrderNoApi = ({ orderNo }) =>
  request.get(`/crm/getMembershipByOrderNo/${orderNo}`);

export const putOrderApi = data => request.put('/crm/updateOrderDetails', data);

export const cancelShipmentApi = ({ orderId }) =>
  request.post(`/adc/cancelOrder/${orderId}`);

export const getLogisticFailedOrdersApi = () =>
  request.get('/crm/manualOrders');

export const getLogisticPartnersApi = () =>
  request.get(`/adc/getlogisticsPartners`);

export const updateFailedOrderApi = data =>
  request.post('/adc/manualLogiticStatusUpdate', data);

export const updateFailedOrderStatusApi = data =>
  request.post('/adc/manualOrderConfirm', data);

export const markLogisticFailedApi = data =>
  request.post('/adc/markLogisticFailed', data);

export const getRescheduleSlotsApi = ({ orderId }) =>
  request.get(`/adc/availableDeliverySlots/${orderId}`);

export const postRescheduleOrderApi = data =>
  request.post('/adc/reScheduleSlot', data);

export const getOTPDetailsForOrderApi = data =>
  request.post('/adc/showOrderPickupOTP', data);

export const getOrderCRRByNoApi = ({ orderNo }) =>
  request.get(`/crm/order/${orderNo}`);
// request.get(`/crm/order-crr/${orderNo}`);

export const postOrderCRRApi = data => request.post('/crm/orderCrr', data);

export const getDeliverySlotsApi = data =>
  request.post('/crm/getDeliverySlotsCRM', data);

export const getPickupOTPApi = ({ orderNo }) =>
  request.get(`/adc/adcPickupOTP/${orderNo}`);

export const getOrderPaymentStatusApi = ({ pgId, orderNo }) =>
  request.get(`/crm/fetchOrderPaymentStatus/${pgId}/${orderNo}`);
