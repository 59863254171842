import AuthService from '../../util/AuthService';
import RolesList from './RolesList';
import UsersList from './UsersList';
import RoleDetails from './RoleDetails';
import AuthCodes from './AuthCodes';
const { MENU_SET, MENU_SECTIONS, MENU_AUTH_CODE } = AuthService;

export const authSet = {
  VIEW_ROLES: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.ROLES,
    authority: MENU_AUTH_CODE.VIEW_ROLES,
  },
  ADD_NEW_ROLE: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.ROLES,
    authority: MENU_AUTH_CODE.ADD_NEW_ROLE,
  },
  EDIT_ROLE_NAME: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.ROLES,
    authority: MENU_AUTH_CODE.EDIT_ROLE_NAME,
  },
  EDIT_ROLE_MAPPING: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.ROLES,
    authority: MENU_AUTH_CODE.EDIT_ROLE_MAPPING,
  },
  VIEW_USERS: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.USERS,
    authority: MENU_AUTH_CODE.VIEW_USERS,
  },
  EDIT_USER_ROLES: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.USERS,
    authority: MENU_AUTH_CODE.EDIT_USER_ROLES,
  },
  RESET_PASSWORD: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.USERS,
    authority: MENU_AUTH_CODE.RESET_PASSWORD,
  },
  VIEW_AUTH_CODES: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.AUTH_CODES,
    authority: MENU_AUTH_CODE.VIEW_AUTH_CODES,
  },
  ADD_AUTH_CODES: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.AUTH_CODES,
    authority: MENU_AUTH_CODE.ADD_AUTH_CODES,
  },
  EDIT_AUTH_CODES: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.AUTH_CODES,
    authority: MENU_AUTH_CODE.EDIT_AUTH_CODES,
  },
};

export const AuthRoutes = [
  {
    path: '/roles',
    component: RolesList,
    title: 'Auth & Roles',
    isPrivate: true,
    auth: authSet.VIEW_ROLES,
  },
  {
    path: '/users',
    component: UsersList,
    title: 'Users',
    isPrivate: true,
    auth: authSet.VIEW_USERS,
  },
  {
    path: '/role/edit/:roleId',
    component: RoleDetails,
    title: 'Role Details',
    auth: authSet.EDIT_USER_ROLES,
  },
  {
    path: '/auth-codes',
    component: AuthCodes,
    title: 'Auth Codes',
    isPrivate: true,
    auth: authSet.VIEW_AUTH_CODES,
  },
];
