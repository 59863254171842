import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { initializeFCMForMessage } from './util/FirebaseNotificationService';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorReportService from './util/ErrorReportService';

/** redux */
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import rootSaga from './redux/root.saga';
import history from './redux/history';

const store = configureStore();
store.runSaga(rootSaga);

// ErrorReportService.initialize();
initializeFCMForMessage();
const swConfig = {
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        console.log('SW state changed to: ', event.target.state);
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      console.log('Posting message to skip waiting. ');
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
};

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root')
);

export { store };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig);
