import React from 'react';
import { Button, notification, Space, Spin, Tree, Typography } from 'antd';
import {
  getAuthoritiesApi,
  getRoleByIdApi,
  getRolesAuthoritiesApi,
  getRolesAuthorityByIdApi,
  putRoleAuthoritiesApi,
} from '../../redux/feature/auth/auth.api';
import history from '../../redux/history';

const RoleDetails = props => {
  const { roleId } = props.match.params;
  const [roleDetails, setRoleDetails] = React.useState(null);
  const [initialChecks, setInitialChecks] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [expandedKeys, setExpandedKeys] = React.useState([]);
  const [checkedKeys, setCheckedKeys] = React.useState([]);
  const [selectedKeys, setSelectedKeys] = React.useState([]);
  const [autoExpandParent, setAutoExpandParent] = React.useState(true);
  const [treeData, setTreeData] = React.useState([]);
  const onExpand = expandedKeys => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };
  const onCheck = checkedKeys => {
    // console.log('onCheck', checkedKeys);
    setCheckedKeys(checkedKeys);
  };
  const onSelect = (selectedKeys, info) => {
    // console.log('onSelect', info);
    setSelectedKeys(selectedKeys);
  };
  const getTreeData = rawData => {
    let checkedNodes = [],
      expandedNodes = [],
      treeObj = {};
    const tree = rawData.map(menu => {
      const menuData = { key: `menuId--${menu.menuId}`, title: menu.menu };
      treeObj[menu.menuId] = { id: menu.menuId, name: menu.menu, child: {} };
      expandedNodes.push(menuData.key);
      const c1 = menu.menuSectionList.map(section => {
        // expandedNodes.push(section.menuSection);
        const c2Data = {
          key: `sectionId--${menu.menuId}--${section.menuSectionId}`,
          title: section.menuSection,
        };
        treeObj[menu.menuId].child[section.menuSectionId] = {
          id: section.menuSectionId,
          name: section.menuSection,
          child: {},
        };
        checkedNodes = [...checkedNodes];
        const authCodes = section.menuAuthorityCodes.map(
          ({ authorityId, authorityCode }) => {
            const node = {
              key: `authId--${menu.menuId}--${section.menuSectionId}--${authorityId}`,
              title: authorityCode,
            };
            treeObj[menu.menuId].child[section.menuSectionId].child[
              authorityId
            ] = {
              id: authorityId,
              name: authorityCode,
            };
            checkedNodes.push(node.key);
            return node;
          }
        );
        c2Data.children = authCodes;
        return c2Data;
      });
      menuData.children = c1;
      return menuData;
    });
    return { tree, checkedNodes, expandedNodes, treeObj };
  };
  React.useEffect(() => {
    Promise.all([getAuthoritiesApi(), getRoleByIdApi({ roleId })])
      .then(([auths, roleAuths, rA]) => {
        const { tree, expandedNodes, treeObj } = getTreeData(auths.data);
        const { checkedNodes } = getTreeData(roleAuths.data.menuList);
        setTreeData(tree);
        setRoleDetails({ rawData: roleAuths.data, treeObj });
        setInitialChecks(checkedNodes);
        setCheckedKeys(checkedNodes);
        setExpandedKeys(expandedNodes);
        setLoading(false);
      })
      .catch(e => {
        console.log('Err @RoleDetails: ', e);
        setLoading(false);
        notification.error({ message: 'Error loading user roles.' });
      });
  }, []);

  const onSave = () => {
    setLoading(true);
    const reqData = { roleId: roleId, roleName: roleDetails.rawData.roleName };
    const newRoles = {};
    const { treeObj } = roleDetails;
    checkedKeys.map(key => {
      if (key.indexOf('authId--') > -1) {
        const [dummy, menuId, sectionId, authId] = key.split('--');
        const { name: menuName } = treeObj[menuId];
        const { name: sectionName } = treeObj[menuId].child[sectionId];
        const { name: authName } = treeObj[menuId].child[sectionId].child[
          authId
        ];
        if (!newRoles[menuId])
          newRoles[menuId] = { name: menuName, id: menuId, child: {} };
        if (!newRoles[menuId].child[sectionId])
          newRoles[menuId].child[sectionId] = {
            name: sectionName,
            id: sectionId,
            child: {},
          };
        newRoles[menuId].child[sectionId].child[authId] = {
          name: authName,
          id: authId,
        };
      }
    });
    const menuList = [];
    for (let k in newRoles) {
      const menu = newRoles[k];
      const sectionList = [];
      for (let s in menu.child) {
        const section = menu.child[s];
        const menuAuthorityCodes = [];
        for (let a in section.child) {
          const authority = section.child[a];
          menuAuthorityCodes.push({
            authorityId: authority.id,
            authorityCode: authority.name,
          });
        }
        sectionList.push({
          menuSection: section.name,
          menuSectionId: section.id,
          menuAuthorityCodes: menuAuthorityCodes,
        });
      }
      menuList.push({
        menu: menu.name,
        menuId: menu.id,
        menuSectionList: sectionList,
      });
    }
    reqData.menuList = menuList;
    putRoleAuthoritiesApi(reqData)
      .then(resp => {
        notification.success({
          message: `Auth roles for ${reqData.roleName} updated.`,
        });
        history.push('/roles');
        setLoading(false);
      })
      .catch(e => {
        console.log('Err @putRoleAuthoritiesApi: ', e);
        setLoading(false);
        notification.error({ message: 'Error updating auth roles.' });
      });
  };
  return (
    <div>
      <Typography.Title level={3}>
        Role details{' '}
        {roleDetails?.rawData?.roleName &&
          `for : ${roleDetails.rawData.roleName}`}
      </Typography.Title>
      <Spin spinning={loading}>
        <Tree
          style={{ padding: 32, margin: '16px 0' }}
          checkable
          showLine
          showIcon={false}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          treeData={treeData}
        />
        <Space>
          <Button
            type="ghost"
            onClick={() => {
              setCheckedKeys(initialChecks);
            }}
          >
            Reset Changes
          </Button>
          <Button type="primary" onClick={onSave}>
            Save Changes
          </Button>
        </Space>
      </Spin>
    </div>
  );
};

export default RoleDetails;
