export const DATE_FORMAT = 'DD/MMM/YYYY';
export const DATETIME_FORMAT = 'DD/MMM/YYYY hh:mm A ';
export const RETURN_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ';

export const PROMO_TYPE = {
  SHIPPINGFEE: 'SHIPPINGFEE',
  BXGX: 'BXGX',
  REGULAR: 'REGULAR',
  SUBSCRIPTION_DUMMY_PROMO: 'SUBSCRIPTION_DUMMY_PROMO',
  SINGLE_USER_COUPON: 'SINGLE_USER_COUPON',
  THIRD_PARTY_COUPON: 'THIRD_PARTY_COUPON',
};

export const COMBINATION_CRITERIA = ['AND', 'OR', 'CLEAR'];

export const INVOICE_STATUS = ['DRAFT', 'GENERATED', 'COMPLETED'];

export const ORDER_TYPES = {
  PENDING_ORDERS: 'pendingOrder',
  TODAYS_ORDERS: 'todayOrder',
  TOMORROWS_ORDERS: 'tomorrowOrder',
};

export const FORM_LAYOUT = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

export const CHANGE_PWD_FORM_LAYOUT = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const PU_LIST = [{ puCode: 'PU01', name: 'Mahim PU' }];

export const CURRENCY = [{ id: 'INR', name: 'Indian National Rupee (INR/₹)' }];

// export const ACTION_TYPE = [
//   { value: 'PLP', label: 'Category PLP' },
//   { value: 'PDP', label: 'PDP', url: '/product/{productId}/{productName}' },
//   { value: 'SPL_PAGE', label: 'Special Page', url: '/special/{pageName}' },
//   // { value: 'S_PLP', label: 'Search PLP', url: '/search?q={searchQuery}' },
//   { value: 'noAction', label: 'No Action' },
// ];

export const STATIC_NOTIFICATION_TYPE = ['WELCOME'];

export const INVOICE_PRINT_STYLE = `
.invoice-container{
    /*width: 90%;*/
    margin: 0 auto;
    padding: 4px;
    border: 1px solid #e1e1e1;
  }
  .invoice-header {
    border: 1px solid #e1e1e1;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .left-align { text-align: left!important; }
  .float-right { float: right; }
  .supplier {
    display: inline-block;
    vertical-align: top;
    width: 45%;
    text-align: center;
    /* background: cornsilk; */ 
    line-height: 1.3;
    padding: 10px;
  }
  .invoice {
    /* background: cornsilk; */
    line-height: 1.3;
    padding: 10px;
    vertical-align: top;
    width: 45%;
    display: inline-block;
    float: right;
  }
  .invoice span {
    display: inline-block;
  }
  .qr {
    margin: 10px auto 5px;
    height: 200px;
    width: 200px;
    border: 1px solid #e1e1e1;
    /* background: #032f62; */
  }
  .qr img {
      width: 100%;
      height: 100%;
    }
  .items {
    clear: both;
    padding-top: 15px;
  }
  .items thead tr {
    background: #e1e1e1;
    text-align: center;
    font-weight: bold;
    line-height: 1.3;
    padding: 2px 0;
  }
  .items tbody tr td {
    padding: 0 5px;
  }
  .notes { padding-top: 50px}
  .clearfix {
    clear: both;
  }
`;

export const QR_PRINT_STYLE = `
  @page {
    size: 3in 5in;
    margin: 0;
  }
  .product-qr-printer {
    page-break-before: always;
    display: inline-block;
    background-color: rgb(255, 255, 255);
    height: 4.8in;
    width: 3in;
    //border: 1px solid #717171;
    margin-top: 20px;
    padding: 15px 0 5px;
  }
  .qr-code {
    display: inline-block;
    vertical-align: top;
    width: 2.9in;
    height: 2.9in;
  }
  .qr-code img,
  svg {
    width: 100%;
    height: 100%;
  }
  .qr-details {
    padding: 0 10px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    display: inline-block;
    vertical-align: top;
    color: #000000;
    width: 2.9in;
  }
  .qr-details--item {
  }
`;

export const ACTION_TYPE = [
  {
    value: 'PLP',
    label: 'Category PLP',
    urlPattern: '/{category}',
    hasActionValue: true,
  },
  {
    value: 'PDP',
    label: 'PDP',
    urlPattern: '/{category}/{productSeoName}',
    hasActionValue: true,
  },
  {
    value: 'SPL_PAGE',
    label: 'Special Page',
    urlPattern: '/{pageName}-spl',
    hasActionValue: true,
  },
  {
    value: 'LINK',
    label: 'Link (external/internal)',
    urlPattern: '/{linkAddress}',
    hasActionValue: true,
  },
  {
    value: 'NOTIFICATION',
    label: 'Notification',
    urlPattern: '/notifications',
    hasActionValue: false,
  },
  {
    value: 'OFFERS',
    label: 'Offers',
    urlPattern: '/offers',
    hasActionValue: false,
  },
  {
    value: 'ORDER_DETAILS',
    label: 'Order Details',
    urlPattern: '/my/order/{orderNo}',
    hasActionValue: false,
  },
  {
    value: 'PROFILE',
    label: 'Profile',
    urlPattern: '/my/details',
    hasActionValue: false,
  },
  {
    value: 'REFER_N_EARN',
    label: 'Refer N Earn',
    urlPattern: '/my/referrals',
    hasActionValue: false,
  },
  {
    value: 'WALLET',
    label: 'Wallet',
    urlPattern: '/my/halalbox-wallet',
    hasActionValue: false,
  },
  { value: 'CART', label: 'Cart', urlPattern: '/cart', hasActionValue: false },
  {
    value: 'BLOG_DETAIL',
    label: 'Blog Detail',
    urlPattern: '/{blogUrl}-bg',
    hasActionValue: true,
  },
  {
    value: 'BLOG',
    label: 'Blog Listing',
    urlPattern: '/blogs',
    hasActionValue: false,
  },
  {
    value: 'RAMADAN',
    label: 'Ramadan Subscription',
    urlPattern: '/ramadan-subscription',
    hasActionValue: false,
  },
  {
    value: 'LOGIN',
    label: 'Login',
    urlPattern: '/login',
    hasActionValue: false,
  },
  {
    value: 'FAQ',
    label: 'FAQs',
    urlPattern: '/help/faqs',
    hasActionValue: false,
  },
  {
    value: 'TNC',
    label: 'Terms & Conditions',
    urlPattern: '/help/tnc',
    hasActionValue: false,
  },
  {
    value: 'PRIVACY',
    label: 'Privacy Policy',
    urlPattern: '/help/privacy-policy',
    hasActionValue: false,
  },
  {
    value: 'NO_ACTION',
    label: 'No Action',
    urlPattern: null,
    hasActionValue: false,
  },
  {
    value: 'HB_SELECT',
    label: 'HB-Select Landing Page',
    urlPattern: '/halalbox-select',
    hasActionValue: false,
  },
  {
    value: 'HB_POINTS',
    label: 'HB Points',
    urlPattern: '/my/hb-point',
    hasActionValue: false,
  },
  {
    value: 'HB_MONEY',
    label: 'HB Money',
    urlPattern: '/my/hb-money',
    hasActionValue: false,
  },
];

export const REFUND_TO_ORIGINAL_MODE = 'original mode';

export const myOrdersPlatform = ['halalbox'];

export const STATUS_FILTER_ARRAY = [
  { text: 'Active', value: true },
  { text: 'Inactive', value: false },
];

export const YES_NO_FILTER_ARRAY = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export const ADC_FILTER_ARRAY = [
  { text: 'MUM01', value: 'MUM01' },
  { text: 'MUM02', value: 'MUM02' },
  { text: 'MUM03', value: 'MUM03' },
  { text: 'MUM04', value: 'MUM04' },
  { text: 'MUM05', value: 'MUM05' },
  { text: 'MUM06', value: 'MUM06' },
];

export const DEVICE_FILTER_ARRAY = [
  { text: 'Mobile', value: 'mobile' },
  { text: 'Desktop', value: 'desktop' },
  { text: 'Android', value: 'android' },
  { text: 'Iphone', value: 'iphone' },
];

// export const MEMBERSHIP_DURATION_UNIT = ['DAYS', 'MONTHS', 'YEARS'];
export const MEMBERSHIP_DURATION_UNIT = ['days', 'months', 'years'];

export const SERVICE_TYPE_FILTER = [
  { text: 'COMPLETE', value: 'COMPLETE' },
  { text: 'PARTIAL', value: 'PARTIAL' },
];

export const NOTIFICATION_PERMISSIONS = {
  DENIED: 'denied',
  DEFAULT: 'default',
  GRANTED: 'granted',
};

export const dummyActiveTrips = [
  {
    orderId: 30453,
    customerId: 18787,
    orderNo: '20230130RFH8',
    slotTime: '30 Jan 2023 4 PM-6 PM',
    paymentMode: 'HBW',
    amount: 80.0,
    deliveryAddress:
      '805, 10, Municipal Colony, Byculla West, Byculla, Mumbai, Maharashtra 400011, India, ',
    customerName: 'Noman Ansari',
    mobileNumber: '9769140533',
    adcCode: 'MUM04',
    adcAddress:
      'Shop No.2, Ground Floor, 20, B I T Chawl, R B Chandoorkar Marg, Jacob Circle, Agripada, Mumbai, Maharashtra 400011',
    orderDate: '2023-01-30T10:39:54.985+0000',
    orderCurrentStatus: 'ORDER_MARK_LOGISTIC_FAILED',
    orderStatus: [
      'ORDER_LOGISTICS_DELIVERED',
      'ORDER_LOGISTICS_PICKED_UP',
      'ORDER_LOGISTICS_DELIVERY_FAILED',
    ],
    orderPlatform: 'halalbox',
    pgId: 0,
  },
  {
    orderId: 30452,
    customerId: 18787,
    orderNo: '20230130IYJC',
    slotTime: '30 Jan 2023 4 PM-6 PM',
    paymentMode: 'HBW',
    amount: 80.0,
    deliveryAddress:
      '805, 10, Municipal Colony, Byculla West, Byculla, Mumbai, Maharashtra 400011, India, ',
    customerName: 'Noman Ansari',
    mobileNumber: '9769140533',
    adcCode: 'MUM04',
    adcAddress:
      'Shop No.2, Ground Floor, 20, B I T Chawl, R B Chandoorkar Marg, Jacob Circle, Agripada, Mumbai, Maharashtra 400011',
    orderDate: '2023-01-30T10:33:02.371+0000',
    logisticsTrackingNumber: 'c3837b12-672f-439e-90d3-53a94457a60b',
    driverName: 'Noman',
    driverContactNumber: '9769140533',
    orderCurrentStatus: 'ORDER_LOGISTICS_DRIVER_ASSIGNED',
    orderStatus: [
      'ORDER_LOGISTICS_DELIVERED',
      'ORDER_LOGISTICS_PICKED_UP',
      'ORDER_LOGISTICS_DELIVERY_FAILED',
    ],
    orderPlatform: 'halalbox',
    pgId: 0,
  },
  {
    orderId: 30448,
    customerId: 2099,
    orderNo: '20230119ZVFT',
    slotTime: '19 Jan 2023 8 AM-10 AM',
    paymentMode: 'HBW',
    amount: 209.0,
    deliveryAddress:
      's, Vrindavan Co.op Housing Society Shivram Master Wadi, Mori Rd, Mahim, Mumbai, Maharashtra 400016, India, ',
    customerName: 'P@erf',
    mobileNumber: '8866255483',
    adcCode: 'MUM01',
    adcAddress:
      '19-Ground Floor Vrindavan Co.Op Society, Mahim - Sion Link Rd, near Welcome Wafer, Mumbai, Maharashtra 400016',
    orderDate: '2023-01-18T19:16:58.585+0000',
    logisticsTrackingNumber: '123',
    driverName: 'Noman',
    driverVehicleNumber: '123',
    driverContactNumber: '9769140533',
    orderCurrentStatus: 'ORDER_LOGISTICS_DRIVER_ASSIGNED',
    orderStatus: [
      'ORDER_LOGISTICS_DELIVERED',
      'ORDER_LOGISTICS_PICKED_UP',
      'ORDER_LOGISTICS_DELIVERY_FAILED',
    ],
    orderPlatform: 'halalbox',
    pgId: 0,
  },
  {
    orderId: 30444,
    customerId: 18787,
    orderNo: '20230103E28Z',
    slotTime: '04 Jan 2023 8 AM-10 AM',
    paymentMode: 'HBW',
    amount: 309.0,
    deliveryAddress:
      '805, 2nd Floor, Afzal Manshion, 5 Moti Bai Stree, Behind Agripada Police Station, Agripada, Dagdi Chowl, Agripada, Mumbai, Maharashtra 400011, India, ',
    customerName: 'Noman Ansari',
    mobileNumber: '9769140533',
    adcCode: 'MUM04',
    adcAddress:
      'Shop No.2, Ground Floor, 20, B I T Chawl, R B Chandoorkar Marg, Jacob Circle, Agripada, Mumbai, Maharashtra 400011',
    orderDate: '2023-01-03T15:09:04.679+0000',
    orderCurrentStatus: 'ORDER_LOGISTICS_FAILED',
    orderStatus: [
      'ORDER_LOGISTICS_DELIVERED',
      'ORDER_LOGISTICS_PICKED_UP',
      'ORDER_LOGISTICS_DELIVERY_FAILED',
    ],
    orderPlatform: 'halalbox',
    pgId: 0,
  },
  {
    orderId: 30446,
    customerId: 18787,
    orderNo: '20230103Z782',
    slotTime: '04 Jan 2023 8 AM-10 AM',
    paymentMode: 'HBW',
    amount: 309.0,
    deliveryAddress:
      '805, 2nd Floor, Afzal Manshion, 5 Moti Bai Stree, Behind Agripada Police Station, Agripada, Dagdi Chowl, Agripada, Mumbai, Maharashtra 400011, India, ',
    customerName: 'Noman Ansari',
    mobileNumber: '9769140533',
    adcCode: 'MUM04',
    adcAddress:
      'Shop No.2, Ground Floor, 20, B I T Chawl, R B Chandoorkar Marg, Jacob Circle, Agripada, Mumbai, Maharashtra 400011',
    orderDate: '2023-01-03T15:10:55.413+0000',
    orderCurrentStatus: 'ORDER_LOGISTICS_FAILED',
    orderStatus: [
      'ORDER_LOGISTICS_DELIVERED',
      'ORDER_LOGISTICS_PICKED_UP',
      'ORDER_LOGISTICS_DELIVERY_FAILED',
    ],
    orderPlatform: 'halalbox',
    pgId: 0,
  },
  {
    orderId: 30442,
    customerId: 18787,
    orderNo: '20230103ESNG',
    slotTime: '03 Jan 2023 8 PM-9 PM',
    paymentMode: 'HBW',
    amount: 309.0,
    deliveryAddress:
      '805, 2nd Floor, Afzal Manshion, 5 Moti Bai Stree, Behind Agripada Police Station, Agripada, Dagdi Chowl, Agripada, Mumbai, Maharashtra 400011, India, ',
    customerName: 'Noman Ansari',
    mobileNumber: '9769140533',
    adcCode: 'MUM04',
    adcAddress:
      'Shop No.2, Ground Floor, 20, B I T Chawl, R B Chandoorkar Marg, Jacob Circle, Agripada, Mumbai, Maharashtra 400011',
    orderDate: '2023-01-03T14:57:42.752+0000',
    orderCurrentStatus: 'ORDER_LOGISTICS_FAILED',
    orderStatus: [
      'ORDER_LOGISTICS_DELIVERED',
      'ORDER_LOGISTICS_PICKED_UP',
      'ORDER_LOGISTICS_DELIVERY_FAILED',
    ],
    orderPlatform: 'halalbox',
    pgId: 0,
  },
  {
    orderId: 30438,
    customerId: 18787,
    orderNo: '20230103NAV7',
    slotTime: '03 Jan 2023 8 PM-9 PM',
    paymentMode: 'HBW',
    amount: 309.0,
    deliveryAddress:
      '805, 2nd Floor, Afzal Manshion, 5 Moti Bai Stree, Behind Agripada Police Station, Agripada, Dagdi Chowl, Agripada, Mumbai, Maharashtra 400011, India, ',
    customerName: 'Noman Ansari',
    mobileNumber: '9769140533',
    adcCode: 'MUM04',
    adcAddress:
      'Shop No.2, Ground Floor, 20, B I T Chawl, R B Chandoorkar Marg, Jacob Circle, Agripada, Mumbai, Maharashtra 400011',
    orderDate: '2023-01-03T14:04:14.349+0000',
    orderCurrentStatus: 'ORDER_LOGISTICS_FAILED',
    orderStatus: [
      'ORDER_LOGISTICS_DELIVERED',
      'ORDER_LOGISTICS_PICKED_UP',
      'ORDER_LOGISTICS_DELIVERY_FAILED',
    ],
    orderPlatform: 'halalbox',
    pgId: 0,
  },
];
