import React from 'react';
import { Route, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import logo from './logo.svg';
import './App.scss';
import 'antd/dist/antd.min.css';
// import 'ant-design-pro/dist/ant-design-pro.min.css';
import {
  Button,
  Drawer,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  notification,
} from 'antd';
import LoginComponent from './feature/login/Login';
// import { productRoutes } from './feature/products/product.routes';
//
// import { adcPincodeRoutes } from './feature/adc-pincode/adcpincode.routes';

import { getInitialInfo } from './redux/initial.action';
import SideNav from './components/SideNav';
import Dashboard from './feature/dashboard/Dashboard';
import PrivateRoute from './components/PrivateRoute';
// import { customerRoutes } from './feature/customer/customer.routes';
// import { orderRoutes } from './feature/order/order.routes';
// import { customTilesRoutes } from './feature/custom-tiles/custom-tiles.routes';
import HomepageSetup from './feature/homepage-setup/HomepageSetup';
import ReferralSetup from './feature/referral/ReferralSetup';
// import { PromoRoutes } from './feature/promo/promo.routes';
// import { PuRoutes } from './feature/pu/pu.routes';
import OnScanDemo from './feature/pu/demo/OnScanDemo';
import { getLogout } from './redux/feature/login/login.action';
// import { BlogRoutes } from './feature/blog/blog.routes';
import AuthService from './util/AuthService';
import UnauthorizedAccess from './feature/UnauthorizedAccess';
import { AuthRoutes } from './feature/auth/auth.routes';
import {
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  CHANGE_PWD_FORM_LAYOUT,
  NOTIFICATION_PERMISSIONS,
} from './util/ConstantsService';
import { resetPasswordApi } from './redux/feature/auth/auth.api';
// import Pages from './feature/special-pages/Pages';
// import PageDetails from './feature/special-pages/PageDetails';
// import { SpecialPagesRoutes } from './feature/special-pages/special-pages.routes';
// import { ComboProductsRoutes } from './feature/combo-products/ComboProducts.routes';
// import AdcRoutes from './feature/adc-boundary/adc.routes';
// import { SubscriptionRoutes } from './feature/subscription/subscription.routes';
// import { NotificationRoutes } from './feature/notification/Notification.routes';
// import { AppUpdateRoutes } from './feature/app-update/update.routes';
// import { HBPointsRoutes } from './feature/hb-points/hb-points.routes';
// import { MarketingDashboardRoutes } from './feature/marketing-dashboard/marketing-dashboard.routes';
// import { provisionalCategoryRoutes } from './feature/provisional-category/provisional-category.routes';
// import { LogisticTrackingRoutes } from './feature/logistic-tracking/logistic-tracking.routes';
// import { HbPrimeRoutes } from './feature/hb-prime/hb-prime.routes';
import NavBar from './components/NavBar';
// import { systemPropertyRoutes } from './feature/system-property/system-property.routes';
// import { seoSettingRoutes } from './feature/seo-setting/seo-setting.routes';
import ErrorReportService from './util/ErrorReportService';
import { DeliveryBoxRoutes } from './feature/delivery-box/delivery-box.routes';
import { requestFCMToken } from './util/FirebaseNotificationService';

const { MENU_SET, MENU_SECTIONS, MENU_AUTH_CODE, getAuthCodes } = AuthService;
const { Header, Content } = Layout;
const rollbarInstance = ErrorReportService.getRollbarInstance();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.getInitialInfo();
    this.state = {
      showResetPasswordModal: false,
      loading: false,
      collapsed: false,
      notificationPermission: NOTIFICATION_PERMISSIONS.DEFAULT,
      notificationButtonText: 'Allow Notifications',
    };
    this.formRef = React.createRef();
    window.addEventListener('UNAUTHORIZED_ACCESS', () => {
      notification.error({ message: 'Unauthorised access. Please re-login.' });
    });
    window.addEventListener('beforeinstallprompt', event => {
      console.log('User prompted for A2HS');
      event.userChoice?.then &&
        event.userChoice.then(choiceResult => {
          console.log('A2HS Result: ', choiceResult.outcome);
        });
    });
    // requestFCMToken().then(token => console.log('Token requested: ', token));
  }

  createRoutes(feature) {
    return feature.map(route => {
      if (route.isPrivate) {
        return (
          <PrivateRoute
            exact
            component={route.component}
            path={route.path}
            key={route.path}
            auth={route.auth}
          />
        );
      } else {
        return (
          <Route
            exact
            component={route.component}
            path={route.path}
            key={route.path}
          />
        );
      }
    });
  }

  changePassword = values => {
    this.setState({ loading: true }, () => {
      const { email, oldPassword, password } = values;
      const data = { email, oldPassword, password };
      resetPasswordApi(data)
        .then(resp => {
          notification.success({ message: 'Password changed successfully.' });
          this.setState({ loading: false, showResetPasswordModal: false });
          rollbarInstance.error(
            'Change Password Success for user: ',
            data.email
          );
        })
        .catch(e => {
          console.log('Err @changePassword: ', e);
          const message = e.data.description || 'Fail to change password.';
          notification.error({ message });
          this.setState({ loading: false });
          rollbarInstance.error(
            'Change Password Failed for user: ',
            data.email
          );
        });
    });
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  setNotificationState = result => {
    console.log(result);
    const newState = { notificationPermission: result };
    if (result === NOTIFICATION_PERMISSIONS.DENIED) {
      newState.notificationButtonText =
        'Notifications are disabled. Pls allow notification from site/browser settings.';
    } else if (result === NOTIFICATION_PERMISSIONS.DEFAULT) {
      newState.notificationButtonText = 'Pls allow notifications.';
    } else {
      newState.notificationButtonText = 'Notifications are allowed.';
    }
    this.setState(newState);
  };
  requestNotificationPermission = () => {
    Notification.requestPermission().then(this.setNotificationState);
  };
  componentDidMount() {
    this.setNotificationState(Notification.permission);
  }

  render() {
    const {
      initialCheckDone,
      // isLoggedIn,
      role,
      logout,
      email,
      userFullName,
    } = this.props;
    const { notificationPermission, notificationButtonText } = this.state;
    const menu = (
      <Menu>
        <Menu.Item
          key="1"
          icon={<UserOutlined />}
          onClick={() => {
            this.setState({ showResetPasswordModal: true }, () => {
              setTimeout(() => {
                if (this.formRef?.current?.setFieldsValue) {
                  this.formRef.current.setFieldsValue({ email, userFullName });
                }
              });
            });
          }}
        >
          Change Password
        </Menu.Item>
        <Menu.Item key="2" icon={<LogoutOutlined />} onClick={logout}>
          Logout
        </Menu.Item>
      </Menu>
    );

    // console.log('PathName: ',this.props?.location?.pathname);
    const isLoggedIn = this.props?.location?.pathname !== '/';
    return (
      <div className="App">
        <NavBar
          initialCheckDone={initialCheckDone}
          isLoggedIn={initialCheckDone && isLoggedIn}
          userFullName={userFullName}
          email={email}
          profileMenu={menu}
          role={role}
          authCodes={getAuthCodes()}
        />

        <Layout>
          {initialCheckDone && (
            <SideNav
              isLoggedIn={initialCheckDone && isLoggedIn}
              role={role}
              authCodes={getAuthCodes()}
            />
          )}
          {initialCheckDone && (
            <Layout className="main-page">
              <Content style={{ padding: '24px' }}>
                <Switch>
                  <Route path="/" exact={true} component={LoginComponent} />
                  <Route
                    path="/unauthorised"
                    exact={true}
                    component={UnauthorizedAccess}
                  />
                  <PrivateRoute
                    path="/dashboard"
                    exact={true}
                    component={Dashboard}
                    auth={{
                      menu: MENU_SET.CRM,
                      section: true,
                      authority: true,
                    }}
                  />
                  {/*{this.createRoutes(MarketingDashboardRoutes)}*/}
                  {/*{this.createRoutes(productRoutes)}*/}
                  {/*{this.createRoutes(provisionalCategoryRoutes)}*/}
                  {/*{this.createRoutes(ComboProductsRoutes)}*/}
                  {/*{this.createRoutes(customerRoutes)}*/}
                  {/*{this.createRoutes(orderRoutes)}*/}
                  {/*{this.createRoutes(customTilesRoutes)}*/}
                  {/*{this.createRoutes(PromoRoutes)}*/}
                  {/*{this.createRoutes(PuRoutes)}*/}
                  {/*{this.createRoutes(BlogRoutes)}*/}
                  {/*{this.createRoutes(AuthRoutes)}*/}
                  {/*{this.createRoutes(SpecialPagesRoutes)}*/}
                  {/*{this.createRoutes(NotificationRoutes)}*/}
                  {/*{this.createRoutes(AppUpdateRoutes)}*/}
                  {/*{this.createRoutes(HBPointsRoutes)}*/}
                  {/*{this.createRoutes(LogisticTrackingRoutes)}*/}
                  {/*{this.createRoutes(HbPrimeRoutes)}*/}
                  {/*{this.createRoutes(systemPropertyRoutes)}*/}
                  {/*{this.createRoutes(seoSettingRoutes)}*/}
                  {/*{this.createRoutes(adcPincodeRoutes)}*/}
                  {this.createRoutes(DeliveryBoxRoutes)}
                  {/*<PrivateRoute*/}
                  {/*  path="/special-pages"*/}
                  {/*  exact={true}*/}
                  {/*  component={Pages}*/}
                  {/*/>*/}
                  {/*<PrivateRoute*/}
                  {/*  path="/special-pages/:pageName"*/}
                  {/*  exact={true}*/}
                  {/*  component={PageDetails}*/}
                  {/*/>*/}
                  {/*{this.createRoutes(AdcRoutes)}*/}
                  {/*{this.createRoutes(SubscriptionRoutes)}*/}
                  <PrivateRoute
                    path="/homepage-setup"
                    exact={true}
                    component={HomepageSetup}
                    auth={{
                      menu: MENU_SET.CRM,
                      section: MENU_SECTIONS.HOMEPAGE_SETUP,
                      authority: MENU_AUTH_CODE.HOMEPAGE_SECTION,
                    }}
                  />
                  <PrivateRoute
                    path="/referral-setup"
                    exact={true}
                    component={ReferralSetup}
                  />
                  <Route
                    path="/on-scan-demo"
                    component={OnScanDemo}
                    exact={true}
                    auth={{
                      menu: MENU_SET.PU,
                      section: MENU_SECTIONS.ON_SCAN_DEMO,
                      authority: MENU_AUTH_CODE.ON_SCAN_DEMO_SECTION,
                    }}
                  />
                </Switch>
              </Content>
            </Layout>
          )}
        </Layout>
        <Modal
          visible={this.state.showResetPasswordModal}
          onOk={() => this.formRef.current.submit()}
          onCancel={() => {
            this.setState({ showResetPasswordModal: false });
          }}
          title="Change password"
          confirmLoading={this.state.loading}
          okText="Change"
        >
          <Form
            {...CHANGE_PWD_FORM_LAYOUT}
            autoComplete="off"
            ref={this.formRef}
            onFinish={this.changePassword}
          >
            <Form.Item name="userFullName" label="User Name">
              <Input disabled />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="oldPassword"
              label="Current Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your old password!',
                },
              ]}
            >
              <Input.Password autoComplete="off" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your new password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your new password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!'
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
        {notificationPermission !== NOTIFICATION_PERMISSIONS.GRANTED && (
          <div className="allow-notification-cta">
            <Button
              type={'primary'}
              disabled={
                notificationPermission === NOTIFICATION_PERMISSIONS.DENIED
              }
              onClick={this.requestNotificationPermission}
            >
              {notificationButtonText}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ initialReducer }) => ({
  initialCheckDone: initialReducer.initialCheckDone,
  isLoggedIn: initialReducer.isLoggedIn,
  role: initialReducer.role,
  email: initialReducer.email,
  userFullName: initialReducer.userFullName,
});

const mapDispatchToProps = {
  getInitialInfo,
  logout: getLogout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
