const LS = window.localStorage;

export const getItem = key => {
  const stringifiedValue = (LS && LS.getItem(key)) || undefined;
  try {
    let parsedJSON = JSON.parse(stringifiedValue);
    return parsedJSON;
  } catch (e) {
    console.warn(`LS: er: ${key}-${stringifiedValue}: `, e);
    return stringifiedValue;
  }
};

export const setItem = (key, value) =>
  LS && LS.setItem(key, JSON.stringify(value));

export const removeItem = key => LS && LS.removeItem(key);
