import React from 'react';
import {
  getRolesApi,
  postRoleApi,
  putRoleApi,
} from '../../redux/feature/auth/auth.api';
import {
  Button,
  Collapse,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import AuthService from '../../util/AuthService';
import { authSet } from './auth.routes';
const { isAuthorisedUser } = AuthService;

const RolesList = props => {
  const [authRoles, setAuthRoles] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [showNewRoleModal, setNewRoleModal] = React.useState(false);
  const [isNewRole, setIsNewRole] = React.useState(false);
  const columns = [
    {
      title: 'Menu',
      dataIndex: 'menu',
    },
    {
      title: 'Section',
      dataIndex: 'section',
    },
    {
      title: 'Auth Code',
      dataIndex: 'authCode',
    },
  ];
  const getRoles = () => {
    getRolesApi()
      .then(resp => {
        const roles = [];
        resp.data.map(role => {
          let menuDetails = [];
          if (role.menuList?.length) {
            role.menuList.map(menu => {
              if (menu.menuSectionList.length) {
                menuDetails = menu.menuSectionList.map((section, index) => ({
                  key: `${index}--${menu.menu}--${section.menuSection}`,
                  menu: menu.menu,
                  section: section.menuSection,
                  authCode: section.menuAuthorityCodes
                    .map(auth => auth.authorityCode)
                    .join(', '),
                }));
              }
            });
          }
          roles.push({ role: role.roleName, roleId: role.roleId, menuDetails });
        });
        setAuthRoles(roles);
        setLoading(false);
      })
      .catch(e => {
        notification.error({
          message: 'Cannot list roles and auth. Please try again later.',
        });
        setLoading(false);
      });
  };
  React.useEffect(getRoles, []);
  const saveRole = values => {
    setLoading(true);
    let api = postRoleApi;
    if (values.roleId) api = putRoleApi;
    api(values)
      .then(resp => {
        getRoles();
        setNewRoleModal(false);
        notification.success({
          message: isNewRole ? 'New role created.' : 'Role name update',
        });
      })
      .catch(e => {
        console.log('Err @saveRole: ', e);
        notification.error({ message: 'Error saving role.' });
        setLoading(false);
      });
  };
  const [formRef] = Form.useForm();
  return (
    <div>
      <Typography.Title level={3}>Auth & Roles</Typography.Title>
      <Space size="large">
        {isAuthorisedUser(authSet.ADD_NEW_ROLE) && (
          <Button
            type="primary"
            onClick={() => {
              setIsNewRole(true);
              setNewRoleModal(true);
            }}
            style={{ margin: '16px 0' }}
          >
            Add new role
          </Button>
        )}
        {isAuthorisedUser(authSet.VIEW_AUTH_CODES) && (
          <Link type="primary" style={{ margin: '16px 0' }} to={'/auth-codes'}>
            View Auth Codes
          </Link>
        )}
      </Space>
      <Spin spinning={loading}>
        <Collapse>
          {authRoles?.map(role => {
            return (
              <Collapse.Panel
                key={role.role}
                header={
                  <Space>
                    <span>{role.role}</span>
                    {isAuthorisedUser(authSet.EDIT_ROLE_NAME) && (
                      <Button
                        type="link"
                        onClick={() => {
                          setIsNewRole(false);
                          formRef.resetFields();
                          formRef.setFieldsValue({
                            roleId: role.roleId,
                            roleLevel: role.role,
                          });
                          setNewRoleModal(true);
                        }}
                      >
                        Edit Role Name
                      </Button>
                    )}
                  </Space>
                }
                extra={
                  <span>
                    {isAuthorisedUser(authSet.EDIT_ROLE_MAPPING) && (
                      <Link to={`/role/edit/${role.roleId}`}>
                        Edit Role Mapping
                      </Link>
                    )}
                  </span>
                }
              >
                <Table
                  columns={columns}
                  dataSource={role.menuDetails}
                  loading={loading}
                  rowKey="key"
                  scroll={{ x: 800 }}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </Spin>
      <Modal
        destroyOnClose={true}
        onOk={() => formRef.submit()}
        onCancel={() => {
          setNewRoleModal(false);
        }}
        visible={showNewRoleModal}
        title={isNewRole ? 'Add new role' : 'Edit Role Name'}
        confirmLoading={loading}
        okText={isNewRole ? 'Add' : 'Update'}
      >
        <Form form={formRef} layout="vertical" onFinish={saveRole}>
          <Form.Item noStyle name="roleId">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="roleLevel" label="Role name:">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RolesList;
