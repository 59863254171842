// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD9dWCKdjAJdxvaNhOZ1aTCAfhERK8Cf3Q',
  authDomain: 'my-driver-box.firebaseapp.com',
  projectId: 'my-driver-box',
  storageBucket: 'my-driver-box.appspot.com',
  messagingSenderId: '936969995232',
  appId: '1:936969995232:web:e417b94ace39f24a496185',
  measurementId: 'G-ZTPH316E3M',
};
const fcmVapidKey =
  'BFWfmdNImbahs28i9lRe-BxY_Lzz5vLe6sJqGAkFwqg856zpTLn8hsw51mV3QPfy5nGbUTEFGXaJ_luKr0Bupf4';

// Initialize Firebase
let app = null;
let fcmApp = null;

export const getFirebaseApp = () => {
  if (!app) app = initializeApp(firebaseConfig);
  return app;
};

export const getFCMMessagingApp = () => {
  if (!fcmApp) fcmApp = getMessaging(getFirebaseApp());
  return fcmApp;
};

export const requestFCMToken = () => {
  return getToken(getFCMMessagingApp(), { vapidKey: fcmVapidKey });
};

export const initializeFCMForMessage = () => {
  onMessage(getFCMMessagingApp(), payload => {
    console.log('Message received. ', JSON.stringify(payload));
    // const samplePayload =
    // {"from":"936969995232","collapseKey":"campaign_collapse_key_497708964560195208","messageId":"1eefdc0c-cb19-464f-bab0-a506b3573775","notification":{"title":"sdfgsdfg","body":"sdfgsdfg","image":"https://dev.myhalalbox.in/static/media/icon-halalbox-navbar.d3c8ea99.svg"},"data":{"gcm.n.e":"1","google.c.a.ts":"1675159801","google.c.a.udt":"0","google.c.a.e":"1","google.c.a.c_id":"497708964560195208","google.c.a.c_l":"sdfgsdfg"}}
    new Notification(payload.notification.title, {
      body: payload.notification.body,
    });
  });
};
