import { call, put } from 'redux-saga/effects';
import { getDeliveryLaunchInfoApi, getLaunchInfoApi } from './initial.api';
import { SET_INITIAL_INFO } from './initial.action';
import { notification } from 'antd';
import AuthService from '../util/AuthService';
import { requestFCMToken } from '../util/FirebaseNotificationService';
const { setAuthCodes } = AuthService;

export function* initialCheckSaga() {
  try {
    const launchResp = yield call(getDeliveryLaunchInfoApi);
    console.log('DeliveryLaunch: ', launchResp);
    const { data: resp } = yield call(getLaunchInfoApi);
    const respData = {
      launchInfo: resp,
      isLoggedIn: resp.loggedIn,
      initialCheckDone: true,
      role: resp.role,
      email: resp.email,
      userFullName: resp.userFullName,
    };
    setAuthCodes(resp.authResponse?.menuList);
    yield put({
      type: SET_INITIAL_INFO,
      data: respData,
    });
    // requestFCMToken().then(token => {
    //   console.log('Token: ', token);
    //   if (token) {
    //     notification.success({ message: `Token: ${token}` });
    //   } else {
    //     notification.error({ message: `Token: ${token}` });
    //   }
    // });
    // const location = yield call(getLocationFromCDNApi);
    // console.log('location data: ', location);
  } catch (e) {
    console.log('Err@ initialCheckSaga: ', e);
    yield put({
      type: SET_INITIAL_INFO,
      data: { initialCheckDone: true },
    });
    notification.error({
      message: e?.data?.description || 'Error while launching app. ',
    });
  }
}
