import React from 'react';
import {
  getPageSettings,
  updatePageSettings,
} from '../../redux/feature/page-settings/page-settings.action';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
  Switch,
  Typography,
} from 'antd';
import AuthService from '../../util/AuthService';
const {
  isAuthorisedUser,
  MENU_AUTH_CODE,
  MENU_SET,
  MENU_SECTIONS,
} = AuthService;

class HomepageSetup extends React.Component {
  state = {
    pageSettings: null,
    loading: true,
  };
  formRef = React.createRef();
  layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  componentDidMount() {
    this.props
      .getPageSettings({ name: 'homepage' })
      .then(({ data }) => {
        this.formRef.current.setFieldsValue(data);
        this.setState({ pageSettings: data, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  // setPageSection = () => {
  // actionUri: "actionUri5............."
  // androidPosition: 6
  // apiUri: "/api/blogs"
  // cityId: 0
  // count: 4
  // description: "show all Blogs"
  // desktopPosition: 6
  // iosPosition: 6
  // isAndroid: true
  // isDesktop: true
  // isIos: true
  // isMweb: true
  // isShowName: false
  // mwebPosition: 6
  // name: "Blogs"
  // pageName: "homepage"
  // pageSectionId: 5
  // sectionType: "BLOGS"
  // status: true
  // }

  onFinish = fieldsValue => {
    this.props.updatePageSettings(fieldsValue);
  };

  onFinishFailed = errors => {
    console.log('onFinishFailed:', errors);
  };

  render() {
    const { pageSettings, loading } = this.state;
    return (
      <div className="homepage-settings">
        <Typography.Title level={3}>Homepage Setup</Typography.Title>
        <Spin spinning={loading}>
          {
            <Form
              {...this.layout}
              ref={this.formRef}
              name="homepage-setup-form"
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Divider orientation="left">Primary Settings</Divider>
              <Form.Item noStyle name="pageId">
                <Input type={'hidden'} />
              </Form.Item>
              <Form.Item noStyle name="isSpecialPage">
                <Input type={'hidden'} />
              </Form.Item>
              <Form.Item label="Name" name="name">
                <Input maxLength="20" placeholder="Page Name" disabled />
              </Form.Item>
              <Form.Item
                label="Show page name?"
                name="isShowName"
                valuePropName="checked"
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input maxLength="30" placeholder="Description" />
              </Form.Item>

              <Form.Item label="Status" name="status" valuePropName="checked">
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  disabled
                />
              </Form.Item>
              <Divider orientation="left">Section Details</Divider>
              {pageSettings && (
                <Form.List name="pageSections">
                  {fields => {
                    return fields.map(f => {
                      return (
                        <Card
                          size="small"
                          type="inner"
                          style={{ marginBottom: 16 }}
                          title={pageSettings?.pageSections[f.key]?.sectionType}
                          key={f.key}
                          extra={
                            <Form.Item
                              label="Section Status?"
                              name={[f.name, 'status']}
                              valuePropName="checked"
                              labelCol={12}
                              wrapperCol={12}
                            >
                              <Switch
                                checkedChildren="Active"
                                unCheckedChildren="Inactive"
                              />
                            </Form.Item>
                          }
                        >
                          <Row>
                            <Col span={6}>
                              <Card size="small" title="Desktop">
                                <Form.Item
                                  label="Show on desktop"
                                  name={[f.name, 'isDesktop']}
                                  valuePropName="checked"
                                  labelCol={12}
                                  wrapperCol={12}
                                >
                                  <Checkbox />
                                </Form.Item>
                                <Form.Item
                                  label="Position"
                                  name={[f.name, 'desktopPosition']}
                                  labelCol={12}
                                  wrapperCol={12}
                                >
                                  <InputNumber />
                                </Form.Item>
                                {/*<Form.Item noStyle shouldUpdate={(prevValues, currentValues) => {*/}
                                {/*    return prevValues?.pageSections[f.key]?.isDesktop !== currentValues?.pageSections[f.key]?.isDesktop*/}
                                {/*  }}*/}
                                {/*  >*/}
                                {/*  {({getFieldValue}) => {*/}
                                {/*    const isDesktopEnabled = getFieldValue('pageSections')[f.key].isDesktop;*/}
                                {/*    return <Form.Item label="Position"*/}
                                {/*                      labelCol={12} wrapperCol={12}*/}
                                {/*                      name={[f.name, 'desktopPosition']}>*/}
                                {/*      <InputNumber disabled={!isDesktopEnabled}/>*/}
                                {/*    </Form.Item>*/}
                                {/*    ;*/}
                                {/*  }}*/}

                                {/*</Form.Item>*/}
                              </Card>
                            </Col>
                            <Col span={6}>
                              <Card size="small" title="m-web">
                                <Form.Item
                                  label="Show on Mweb"
                                  name={[f.name, 'isMweb']}
                                  valuePropName="checked"
                                  labelCol={12}
                                  wrapperCol={12}
                                >
                                  <Checkbox />
                                </Form.Item>
                                <Form.Item
                                  label="Position"
                                  name={[f.name, 'mwebPosition']}
                                  labelCol={12}
                                  wrapperCol={12}
                                >
                                  <InputNumber />
                                </Form.Item>
                              </Card>
                            </Col>
                            <Col span={6}>
                              <Card size="small" title="iOS">
                                <Form.Item
                                  label="Show on iOS"
                                  name={[f.name, 'isIos']}
                                  valuePropName="checked"
                                  labelCol={12}
                                  wrapperCol={12}
                                >
                                  <Checkbox />
                                </Form.Item>
                                <Form.Item
                                  label="Position"
                                  name={[f.name, 'iosPosition']}
                                  labelCol={12}
                                  wrapperCol={12}
                                >
                                  <InputNumber />
                                </Form.Item>
                              </Card>
                            </Col>
                            <Col span={6}>
                              <Card size="small" title="Android">
                                <Form.Item
                                  label="Show on Android"
                                  name={[f.name, 'isAndroid']}
                                  valuePropName="checked"
                                  labelCol={12}
                                  wrapperCol={12}
                                >
                                  <Checkbox />
                                </Form.Item>
                                <Form.Item
                                  label="Position"
                                  name={[f.name, 'androidPosition']}
                                  labelCol={12}
                                  wrapperCol={12}
                                >
                                  <InputNumber />
                                </Form.Item>
                              </Card>
                            </Col>
                          </Row>
                        </Card>
                      );
                    });
                  }}
                </Form.List>
              )}
              <Form.Item wrapperCol={24}>
                {isAuthorisedUser({
                  menu: MENU_SET.CRM,
                  section: MENU_SECTIONS.HOMEPAGE_SETUP,
                  authority: MENU_AUTH_CODE.UPDATE_HOMEPAGE,
                }) && (
                  <Button type="primary" htmlType="submit" block>
                    Update Homepage Settings
                  </Button>
                )}
              </Form.Item>
            </Form>
          }
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ pageSettingsReducer }) => ({
  pageSettings: pageSettingsReducer.pageSettings,
  loadingPage: pageSettingsReducer.loadingPage,
});

const mapDispatchToProps = {
  getPageSettings,
  updatePageSettings,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomepageSetup);
