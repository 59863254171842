import React from 'react';
import {
  getMenusApi,
  postMenuApi,
  putMenuApi,
} from '../../redux/feature/auth/auth.api';
import { Button, Form, Input, Modal, notification, Space, Table } from 'antd';
import AuthService from '../../util/AuthService';
import { authSet } from '../../feature/auth/auth.routes';
const { isAuthorisedUser } = AuthService;

const MenuList = props => {
  const [loading, setLoading] = React.useState(true);
  const [menus, setMenus] = React.useState(null);
  const [showNewMenuModal, setNewMenuModal] = React.useState(false);
  const [isNewMenu, setIsNewMenu] = React.useState(false);
  const [formRef] = Form.useForm();

  const columns = [
    {
      title: 'Menu Id',
      dataIndex: 'menuId',
    },
    {
      title: 'Menu',
      dataIndex: 'menuCode',
    },
    {
      title: 'Action',
      render: (text, record) => (
        <Space>
          {isAuthorisedUser(authSet.EDIT_AUTH_CODES) && (
            <Button
              type="ghost"
              onClick={() => {
                setIsNewMenu(false);
                setNewMenuModal(true);
                formRef.setFieldsValue(record);
              }}
            >
              Edit Menu
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const getMenus = () => {
    getMenusApi()
      .then(resp => {
        setMenus(resp.data);
        setLoading(false);
      })
      .catch(e => {
        console.log('Err @getMenus: ', e);
        setLoading(false);
        notification.error({ message: 'Error loading menus.' });
      });
  };

  React.useEffect(getMenus, []);

  const saveMenu = values => {
    setLoading(true);
    let api = postMenuApi;
    if (values.menuId) api = putMenuApi;
    api(values)
      .then(resp => {
        getMenus();
        setNewMenuModal(false);
        notification.success({
          message: isNewMenu ? 'New menu created.' : 'Menu name update',
        });
      })
      .catch(e => {
        console.log('Err @saveMenu: ', e);
        notification.error({ message: 'Error saving menu.' });
        setLoading(false);
      });
  };

  return (
    <div>
      {isAuthorisedUser(authSet.ADD_AUTH_CODES) && (
        <Button
          type="primary"
          style={{ margin: '16px 0' }}
          onClick={() => {
            setIsNewMenu(true);
            setNewMenuModal(true);
          }}
        >
          Add new menu
        </Button>
      )}
      <Table
        columns={columns}
        dataSource={menus}
        loading={loading}
        rowKey="menuId"
        scroll={{ x: 800 }}
        bordered
        size="small"
      />
      <Modal
        destroyOnClose={true}
        onOk={() => formRef.submit()}
        onCancel={() => {
          setNewMenuModal(false);
        }}
        visible={showNewMenuModal}
        title={isNewMenu ? 'Add new Menu' : 'Edit Menu Name'}
        confirmLoading={loading}
        okText={isNewMenu ? 'Add' : 'Update'}
      >
        <Form form={formRef} layout="vertical" onFinish={saveMenu}>
          <Form.Item noStyle name="menuId">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="menuCode" label="Menu:" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MenuList;
