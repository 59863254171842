import React from 'react';
import { connect } from 'react-redux';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Input,
  Button,
  Form,
  Card,
  Typography,
  Space,
  Radio,
  Select,
  notification,
} from 'antd';
import { postAuthenticate } from '../../redux/feature/login/login.action';
import history from '../../redux/history';
import {
  sendOTPApi,
  signUpApi,
  verifyLoginOTPApi,
} from '../../redux/feature/login/login.api';
import { getAdc } from '../../redux/feature/master-data/master.api';
import { NOTIFICATION_PERMISSIONS } from '../../util/ConstantsService';
import { requestFCMToken } from '../../util/FirebaseNotificationService';

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    const { initialCheckDone, isLoggedIn, role } = this.props;
    if (initialCheckDone && isLoggedIn) {
      history.push('/active-trips');
      // if (role === 'PU_ADMIN') {
      //   history.push('/pu-dashboard');
      // } else {
      //   history.push('/dashboard');
      // }
    }
    this.state = {
      disableSendOtp: true,
      currentForm: 'login',
      adcs: [],
    };
  }
  componentDidMount() {
    getAdc()
      .then(resp => {
        this.setState({ adcs: resp.data });
      })
      .catch(e => {
        notification.error({ message: 'Error fetching preferred location' });
      });
  }

  formRef = React.createRef();
  handleSubmit = values => {
    const { mobileNo, otp } = values;
    if (!mobileNo || isNaN(mobileNo) || `${mobileNo}`.length !== 10) {
      return notification.error({ message: 'Please enter 10 digit phone no!' });
    }
    if (!otp || isNaN(otp) || `${otp}`.length !== 4) {
      return notification.error({ message: 'Please enter 4 digit OTP!' });
    }
    if (Notification.permission !== NOTIFICATION_PERMISSIONS.GRANTED) {
      return notification.error({
        message: 'Pls allow notification permission to proceed.',
      });
    }
    this.setState({ formSubmitInProgress: true }, () => {
      const payload = { ...values, phoneNo: mobileNo };
      requestFCMToken().then(token => {
        console.log('Firebase token: ', token);
        payload.firebaseToken = token;
        payload.broadcastToken = token;
        if (this.state.currentForm === 'login') {
          verifyLoginOTPApi(payload)
            .then(resp => {
              const message = resp.data?.description || 'Login successful.';
              notification.success({ message });
              this.setState({ formSubmitInProgress: false });
              history.push('/active-trips');
            })
            .catch(e => {
              console.log('Err @Login: ', e);
              const error =
                e.data?.description || 'Fail to login. Please try later.';
              notification.error({ message: error });
              this.setState({ formSubmitInProgress: false });
            });
        } else {
          signUpApi(payload)
            .then(resp => {
              // debugger;
              const message = resp.data?.description || 'Signup successful.';
              notification.success({ message });
              this.setState({ formSubmitInProgress: false });
              history.push('/active-trips');
            })
            .catch(e => {
              // debugger;
              console.log('Err @Signup: ', e);
              const error =
                e.data?.description || 'Fail to sign up. Please try later.';
              notification.error({ message: error });
              this.setState({ formSubmitInProgress: false });
            });
        }
      });
    });
  };

  sendOtp = () => {
    if (Notification.permission !== NOTIFICATION_PERMISSIONS.GRANTED) {
      return notification.error({
        message: 'Pls allow notification permission to proceed.',
      });
    }
    const { mobileNo } = this.formRef.current.getFieldsValue();
    if (!mobileNo || isNaN(mobileNo) || `${mobileNo}`.length !== 10) {
      return notification.error({ message: 'Please enter 10 digit phone no!' });
    }
    sendOTPApi({ phoneNo: mobileNo, mobileNo })
      .then(resp => {
        const message =
          resp.data?.message?.description || 'OTP send to your phone';
        notification.success({ message });
      })
      .catch(e => {
        console.log('Err @sendOtp: ', e);
        const message = e.data?.description || 'Fail to send OTP';
        notification.error({ message });
      });
  };
  render() {
    const {
      disableSendOtp,
      currentForm,
      adcs,
      formSubmitInProgress,
    } = this.state;
    return (
      <div className="login-container">
        <Card
          type="inner"
          title="Welcome to DeliveryBox"
          style={{
            width: 320,
          }}
        >
          <Radio.Group
            defaultValue="login"
            buttonStyle="solid"
            value={this.state.currentForm}
            onChange={e => this.setState({ currentForm: e.target.value })}
            style={{ marginBottom: 20 }}
          >
            <Radio.Button value="login">Already a User?</Radio.Button>
            <Radio.Button value="signUp">New User?</Radio.Button>
          </Radio.Group>
          <Form
            onFinish={this.handleSubmit}
            className="login-form"
            ref={this.formRef}
            autoComplete={'new-password'}
          >
            {currentForm === 'signUp' && (
              <React.Fragment>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: 'Please input your name!' },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Name"
                    autoComplete={'new-password'}
                  />
                </Form.Item>
                <Form.Item
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: 'Choose your preferred location!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Choose your preferred location!"
                    autoComplete={'new-password'}
                  >
                    {adcs.map(({ adcCode, areaName }) => (
                      <Select.Option value={adcCode} key={adcCode}>
                        {areaName}
                      </Select.Option>
                    ))}
                    {/*<Select.Option value="OTHERS">Others</Select.Option>*/}
                  </Select>
                </Form.Item>
              </React.Fragment>
            )}
            <Form.Item>
              <Space>
                <Form.Item
                  name="mobileNo"
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Phone No is required',
                    },
                  ]}
                >
                  <Input
                    type="tel"
                    placeholder="10 Digit Phone No"
                    maxLength={10}
                    onChange={e => {
                      const { value } = e.target;
                      this.setState({
                        disableSendOtp:
                          isNaN(value) || `${value}`.length !== 10,
                      });
                    }}
                    autoComplete={'new-password'}
                  />
                </Form.Item>
                <Typography.Link
                  onClick={this.sendOtp}
                  disabled={disableSendOtp}
                >
                  Send OTP
                </Typography.Link>
              </Space>
            </Form.Item>
            <Form.Item
              name="otp"
              rules={[
                {
                  required: true,
                  message: 'OTP is required',
                },
                {
                  len: 4,
                  message: 'OTP must be 4 digit',
                },
              ]}
            >
              <Input
                // prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                maxLength={4}
                type="tel"
                placeholder="OTP"
                autoComplete={'new-password'}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={formSubmitInProgress}
              >
                {currentForm === 'login' ? 'Login' : 'Sign Up'}
              </Button>
              <br />
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ initialReducer, loginReducer }) => ({
  loading: loginReducer.loading,
  isLoggedIn: initialReducer.isLoggedIn,
  initialCheckDone: initialReducer.initialCheckDone,
  role: initialReducer.role,
});

const mapDispatchToProps = {
  postAuthenticate,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
