import React from 'react';
import './pu-demo.scss';
import onScan from './../../../util/OnScanLib';
import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  notification,
  Row,
  Space,
  Table,
} from 'antd';
import moment from 'moment';
import uid from 'uid';
import { DATETIME_FORMAT } from '../../../util/ConstantsService';

class OnScanDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      scannedData: [],
    };
    this.onScanSuccess = props.onScanSuccess || (() => {});
  }
  componentWillUnmount() {
    if (this.state.isActive) {
      onScan.detachFrom(document);
      this.setState({ isActive: false });
    }
  }

  onScanCb = (sCode, iQty) => {
    // let qr = null;
    try {
      // qr = JSON.parse(sCode);
      // if (!qr) return;
      const { scannedData } = this.state;
      console.log('onScan: Scanned: ', sCode);
      const code = { sCode, index: scannedData.length + 1, key: uid(6) };
      const data = [].concat(scannedData);
      data.push(code);
      this.setState({ scannedData: data }, () => {
        this.onScanSuccess(sCode);
      });
    } catch (e) {
      return console.log('JSON Parsing failed: ');
    }
  };
  // https://stackoverflow.com/questions/12467240/determine-if-javascript-e-keycode-is-a-printable-non-control-character/12467610
  keyCodeMapper = e => {
    console.log('ev: ', e);
    const iCode = e.which || e.keyCode;
    let char = '';
    if (iCode === 32) {
      char = ' ';
    } else if (e.key.length === 1) {
      char = e.key;
    }
    console.log('keyCodeMapper: ', char || 'no character');
    return char;
  };
  simulate = () => {
    if (!this.state.isActive) {
      return notification.error({ message: 'Please start scanner: ' });
    }
    const sku = {
      productId: uid(2).toUpperCase(),
      sku: `${uid(2).toUpperCase()}-${uid(6)}`,
      expiryDate: moment().format(DATETIME_FORMAT),
    };
    onScan.simulate(document, JSON.stringify(sku));
    // debugger
    // this.formRef.current.setFieldsValue({ skid: JSON.stringify(sku)});
  };
  toggleScanner = () => {
    if (this.state.isActive) {
      onScan.detachFrom(document);
      this.setState({ isActive: false });
    } else {
      this.setState({ isActive: true }, () => {
        onScan.attachTo(document, {
          // suffixKeyCodes: [13], // enter-key expected at the end of a scan
          reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
          onScan: this.onScanCb,
          avgTimeByChar: 90,
          timeBeforeScanTest: 180,
          onScanError: function(oDebug) {
            console.log('onScanError: ', oDebug);
          },
          onScanButtonLongPress: function() {
            console.log('onScanButtonLongPress: ', arguments);
          },
          onKeyDetect: function(iKeyCode, oEvent) {
            console.log('onKeyDetect: ', iKeyCode, oEvent, arguments);
          },
          onKeyProcess: function(sChar, oEvent) {
            console.log('onKeyProcess: ', sChar, oEvent, arguments);
          },
          onPaste: function(sPasted) {
            console.log('onPaste: ', sPasted, arguments);
          },
          keyCodeMapper: this.keyCodeMapper, // https://stackoverflow.com/questions/12467240/determine-if-javascript-e-keycode-is-a-printable-non-control-character/12467610
          // onKeyPress: function(e) {console.log('onKeyPress: ', e, e.key, e.shiftKey)}
        });
      });
    }
  };
  columns = [
    {
      title: '#',
      dataIndex: 'index',
    },
    {
      title: 'Scanned Code',
      dataIndex: 'sCode',
    },
  ];
  formRef = React.createRef();
  // debouncePromise = null;
  // clearSkidPromise = null;
  // inputChange = ev => {
  //   console.log('Input Change: ', ev);
  //   if (this.debouncePromise) {
  //     this.debouncePromise = null;
  //   }
  //   this.debouncePromise = setTimeout(() => {
  //     console.log('executing timeout: ');
  //     const values = this.formRef.current.getFieldsValue();
  //     const { skid } = values;
  //     console.log('skid: ', skid);
  //     if (skid) {
  //       this.onScanCb(skid);
  //     }
  //     if (this.clearSkidPromise) this.clearSkidPromise = null;
  //     this.clearSkidPromise = setTimeout(() => {
  //       console.log('Clear skid: ');
  //       this.formRef.current.resetFields();
  //     }, 100);
  //   }, 300);
  // };
  onSubmit = values => {
    const { skid } = values;
    //skid should be like : MUT-xtd3,+100392,PU01,2020-09-29T18:29:59.999+0000,false
    // let qr = null;
    // try {
    //   qr = JSON.parse(skid);
    // } catch (e) {
    //   console.log('JSON Parsing failed: ');
    // }
    // if (qr) {
    this.onScanCb(skid);
    setTimeout(() => {
      this.formRef.current.resetFields();
    }, 100);
    // }
  };
  FORM_LAYOUT = {
    labelCol: { span: 3 },
    wrapperCol: { span: 18 },
  };
  render() {
    const { isActive, scannedData } = this.state;
    const { simulate, toggleScanner, columns } = this;
    return (
      <Row>
        <Col className="on-scan-demo-container" span={24}>
          <Collapse style={{ marginBottom: 16 }}>
            <Collapse.Panel header="Scan from Input" key="1">
              <Form
                ref={this.formRef}
                {...this.FORM_LAYOUT}
                onFinish={this.onSubmit}
              >
                <Form.Item
                  label="SKID"
                  name="skid"
                  rules={[{ required: true, message: 'Please input SKID' }]}
                >
                  <Input
                    className="mega-input"
                    placeholder="Skid Scanner Input"
                  />
                </Form.Item>
                <Form.Item label=" " colon={false}>
                  <Button htmlType="submit" type="primary">
                    Scan
                  </Button>
                </Form.Item>
              </Form>
            </Collapse.Panel>
            <Collapse.Panel header="Scan By OnSCAN_JS" key="2">
              <Row>
                <Col span={24}>
                  <Alert
                    message={
                      isActive ? 'Scanner is active.' : 'Scanner is inactive.'
                    }
                    type={isActive ? 'success' : 'error'}
                    showIcon
                  />
                </Col>
                <Col span={24}>
                  <Space size="large">
                    <Button type="ghost" onClick={simulate}>
                      Simulate
                    </Button>
                    <Button type="primary" onClick={toggleScanner}>
                      {isActive ? 'Stop Scanner' : 'Start Scanner'}
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Scanned Result" key="3">
              <Table dataSource={scannedData} columns={columns} />
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    );
  }
}

export default OnScanDemo;
