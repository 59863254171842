import React from 'react';
import { Typography } from 'antd';

const Profile = () => {
  return (
    <>
      <Typography.Title level={2}>My Profile</Typography.Title>
    </>
  );
};

export default Profile;
