import { call, put, all } from 'redux-saga/effects';
import {
  SET_ALL_CT,
  SET_INIT_CT_DETAILS,
  SET_LOADING_CT,
} from './custom-tile.action';
import {
  getAllCTApi,
  getCTByIdApi,
  postCTApi,
  putCTApi,
} from './custom-tile.api';
import {
  getClientType,
  getCustomerType,
  getCategory,
  getProduct,
  getCity,
} from '../master-data/master.api';
import { notification } from 'antd';
import history from '../../history';
import { getAllPagesApi } from '../page-settings/page-settings.api';
import { getBlogsApi } from '../blogs/blogs.api';
import { checkBetween } from '../../../util/miscService';

export function* getInitCTDetailSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_CT, data: true });
    const { ctId } = data;
    const promises = [];
    promises.push(call(getCustomerType));
    promises.push(call(getClientType));
    promises.push(call(getCategory));
    promises.push(call(getProduct));
    promises.push(call(getAllPagesApi));
    promises.push(getBlogsApi());
    promises.push(call(getCity));
    if (ctId) promises.push(call(getCTByIdApi, ctId));
    const promisesResp = yield all(promises);
    const masterData = {
      customerTypes: promisesResp[0].data,
      clientTypes: promisesResp[1].data,
      categories: promisesResp[2].data,
      products: promisesResp[3].data,
      pages: promisesResp[4].data,
      blogs: promisesResp[5].data,
      city: promisesResp[6].data,
    };
    const responseData = { ...masterData };
    if (ctId) {
      responseData.customTileConfig = promisesResp[7].data;
      // TODO: Temporary fix untilwe get imageUUID in columns response.
      responseData.customTileConfig = {
        ...promisesResp[7].data,
        tileDetail: promisesResp[7].data.tileDetail.map(row => ({
          ...row,
          tileGrid: row.tileGrid.map(col => ({
            ...col,
            imageUuid: col.imageUrl
              .split('/')
              .reverse()[0]
              .split('.')[0],
          })),
        })),
      };
    }
    // Process data for customtiles and create responseData
    // Set following properties:
    // const respData = {
    //   customerTypes: null,
    //   clientTypes: null,
    //   actionTypes: null,
    //   customTileConfig: null,
    // }
    yield put({
      type: SET_INIT_CT_DETAILS,
      data: responseData,
    });
    return Promise.resolve(responseData);
  } catch (e) {
    console.log('Err@ getInitCTDetailSaga: ', e);
  }
}

export function* upsertCTSaga({ data }) {
  try {
    let api = postCTApi;
    let message = 'Custom tile added successfully.';
    if (data && data.customTileId) {
      api = putCTApi;
      message = 'Custom tile updated successfully.';
    }
    // console.log('CT Post Data: ', JSON.stringify(data));
    yield call(api, data);
    history.push('/custom-tiles');
    notification.success({ message });
  } catch (e) {
    console.log('Err@ upsertCTSaga: ', e);
    const message =
      e.error?.description ||
      e.error?.errorMessage ||
      'Failed to save custom tile.';
    notification.error({ message });
  }
}

export function* getAllCTSaga() {
  try {
    yield put({ type: SET_LOADING_CT, data: true });
    const { data } = yield call(getAllCTApi);
    yield put({
      type: SET_ALL_CT,
      data: data.map(ct => ({
        ...ct,
        isActiveToday: checkBetween(ct?.startDate, ct?.endDate),
      })),
    });
  } catch (e) {
    console.log('Err@ getAllCTSaga: ', e);
    notification.error('Error fetching customtiles.');
    yield put({ type: SET_LOADING_CT, data: false });
  }
}
