import { call, put, all } from 'redux-saga/effects';
import {
  SET_CUSTOMERS,
  SET_CUSTOMER_BY_ID,
  SET_ADDRESS_BY_ID,
  SET_LOADING_CUSTOMER,
  MERGE_CUSTOMERS,
} from './customer.action';
import {
  getCustomersApi,
  getCustomerByIdApi,
  postCustomerApi,
  putCustomerApi,
  getAddressByCustomerIdApi,
  postAddressApi,
  putAddressApi,
  postOrderForCustomerApi,
  searchCustomersApi,
} from './customer.api';
import { notification } from 'antd';
import history from '../../history';
import { searchOrdersApi } from '../orders/order.api';
import { getPrimeMembershipByCustomerIDApi } from '../hb-prime/hbprime.api';

export function* getCustomersSaga({ data = {} }) {
  try {
    yield put({ type: SET_LOADING_CUSTOMER, data: true });
    const { data: customerList } = yield call(getCustomersApi, data);
    if (data.page) {
      yield put({ type: MERGE_CUSTOMERS, data: customerList });
    } else {
      yield put({ type: SET_CUSTOMERS, data: customerList });
    }
  } catch (e) {
    console.log('Err@ getCustomersSaga: ', e);
    notification.error({
      message: 'Error getting customers. Please try later.',
    });
  }
}

function* getPrimeMembershipByCustomerIDSaga({ customerId }) {
  try {
    const { data: memberships } = yield call(
      getPrimeMembershipByCustomerIDApi,
      { customerId }
    );
    return { data: memberships };
  } catch (e) {
    console.log('Err@ getPrimeMembershipByCustomerIDSaga: ', e);
    notification.error({
      message: 'Error getting prime membership details.',
    });
  }
}

export function* getCustomerByIdSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_CUSTOMER, data: true });
    const customerId = parseInt(data?.customerId);
    const { onlyMembership = false } = data;
    if (onlyMembership) {
      const {
        data: memberships,
      } = yield call(getPrimeMembershipByCustomerIDSaga, { customerId });
      yield put({ type: SET_CUSTOMER_BY_ID, data: { memberships } });
    } else {
      const promises = [
        call(getCustomerByIdApi, customerId),
        call(searchOrdersApi, { customerId }),
        call(getPrimeMembershipByCustomerIDSaga, { customerId }),
      ];
      const [
        customerResp,
        { data: customerOrders },
        { data: memberships },
      ] = yield all(promises);
      yield put({
        type: SET_CUSTOMER_BY_ID,
        data: {
          customerDetails: customerResp.data,
          customerOrders,
          memberships,
        },
      });
    }
  } catch (e) {
    console.log('Err@ getCustomerByIdSaga: ', e);
    notification.error({
      message: 'Error getting customer details. Please try later.',
    });
  }
}

export function* upsertCustomerSaga({ data }) {
  try {
    let api = postCustomerApi;
    let message = 'Customer added successfully.';
    if (data && data.customerId) {
      api = putCustomerApi;
      message = 'Customer details updated successfully.';
    }
    data.password = data.password || 'welcome@123';
    console.log('Upsert Data: ', JSON.stringify(data));
    yield call(api, data);
    notification.success({ message });
    history.push('/customers');
  } catch (e) {
    console.log('Err@ upsertCustomerSaga: ', e);
    notification.error({
      message: 'Error saving customer details. Please try later.',
    });
  }
}

export function* upsertAddressSaga({ data }) {
  try {
    let api = postAddressApi;
    let message = 'Address added successfully.';
    if (data && data.id) {
      api = putAddressApi;
      message = 'Address updated successfully.';
    }
    console.log('Upsert Data: ', JSON.stringify(data));
    const addressResp = yield call(api, data);
    yield put({ type: SET_ADDRESS_BY_ID, data: addressResp.data });
    // history.push('/customers');
    notification.success({ message });
  } catch (e) {
    console.log('Err@ upsertCustomerSaga: ', e);
    notification.error({
      message: 'Error saving customer details. Please try later.',
    });
  }
}

export function* postOrderForCustomerSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_CUSTOMER, data: true });
    const { data: details } = yield call(postOrderForCustomerApi, {
      ...data,
      phoneNo: data.phoneNo,
    });
    const { ccaToken, userUUID, ccaUUID, baseUrl } = details;
    // const baseLoginUrl = `${window.location.origin}/ofc`;
    // const baseLoginUrl = 'localhost:3000/ofc';
    // path="/ofc/:cuid/:ccid/:token"
    //   "ccaToken": "802a85b9-dcae-4ae2-b895-a8d39c39f936",
    //   "userUUID": "006NL6EY00",
    //   "ccaUUID": "GCGFBN8IJL",
    const redirectUrl = `${baseUrl}/ofc/${userUUID}/${ccaUUID}/${ccaToken}`;
    window.open(redirectUrl, '_blank');
    yield put({ type: SET_LOADING_CUSTOMER, data: false });
  } catch (e) {
    console.log('Err @postOrderForCustomerSaga: ', e);
    yield put({ type: SET_LOADING_CUSTOMER, data: false });
    notification.error({
      message: 'Something went wrong. Please try later.',
    });
  }
}

export function* searchCustomersSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_CUSTOMER, data: true });
    const { data: customers } = yield call(searchCustomersApi, data);
    yield put({ type: SET_CUSTOMERS, data: customers });
  } catch (e) {
    console.log('Err @searchCustomersSaga: ', e);
    yield put({ type: SET_LOADING_CUSTOMER, data: false });
    notification.error({
      message: 'Something went wrong. Please try later.',
    });
  }
}
