export const getCategoriesForProductResponse = () => ({
  data: {
    categories: [
      {
        categoryId: 12,
        categoryName: 'CHICKEN-mock',
        subCategories: [
          {
            subCategoryId: 23,
            subCategoryName: 'Raw-mock',
            productClasses: [
              {
                productClassId: 1,
                productClassName: 'Country-mock',
              },
              {
                productClassId: 2,
                productClassName: 'Broiler-mock',
              },
            ],
          },
          {
            subCategoryId: 24,
            subCategoryName: 'Marinade-mock',
            productClasses: [
              {
                productClassId: 1,
                productClassName: 'Country-mock',
              },
              {
                productClassId: 2,
                productClassName: 'Broiler-mock',
              },
            ],
          },
        ],
      },
      {
        categoryId: 4,
        categoryName: "MUTTON-mock (Don't Select) ",
        subCategories: [
          {
            subCategoryId: 5,
            subCategoryName: 'MUTTON-raw-mock',
            productClasses: [
              {
                productClassId: 1,
                productClassName: 'Country-mock',
              },
              {
                productClassId: 2,
                productClassName: 'Broiler-mock',
              },
            ],
          },
          {
            subCategoryId: 6,
            subCategoryName: 'MUTTON-Marinade-mock',
            productClasses: [
              {
                productClassId: 1,
                productClassName: 'Country-mock',
              },
              {
                productClassId: 2,
                productClassName: 'Broiler-mock',
              },
            ],
          },
        ],
      },
      {
        categoryId: 11,
        categoryName: 'EGG-mock',
        subCategories: [
          {
            subCategoryId: 21,
            subCategoryName: 'EGG-Marinade-mock',
            productClasses: [
              {
                productClassId: 1,
                productClassName: 'Country-mock',
              },
              {
                productClassId: 2,
                productClassName: 'Broiler-mock',
              },
            ],
          },
          {
            subCategoryId: 22,
            subCategoryName: 'EGG-raw-mock',
            productClasses: [
              {
                productClassId: 1,
                productClassName: 'Country-mock',
              },
              {
                productClassId: 2,
                productClassName: 'Broiler-mock',
              },
            ],
          },
        ],
      },
    ],
    cancellationStates: [
      { id: 1, name: 'Order waiting for approval' },
      { id: 2, name: 'Order Accepted and In process' },
      { id: 3, name: 'Order In Process and Rider Assigned' },
      { id: 4, name: 'Order Processed and waiting for pickup' },
      { id: 5, name: 'Order Processed and Picked up by Rider' },
      { id: 6, name: 'Order Picked up and on the way for delivery' },
      { id: 7, name: 'Order Delivered' },
    ],
  },
});

export const getAttributesBySubcategoryForProductResponse = subcategory => ({
  data: {
    productAttributes: [
      {
        id: 19,
        name: 'cutType',
        displayName: 'Cut type',
        uiType: 'singleSelect',
        isMandatory: true,
        options: [
          { id: 9, option: 'Small' },
          { id: 10, option: 'Medium' },
          { id: 11, option: 'Large' },
        ],
      },
      {
        id: 29,
        name: 'quantityType',
        displayName: 'Quantity Type',
        uiType: 'singleSelect',
        isMandatory: true,
        options: [
          { id: 12, option: 'Grams' },
          { id: 13, option: 'Kg' },
          { id: 14, option: 'Pieces' },
        ],
      },
      {
        id: 1,
        name: 'color',
        displayName: 'Color',
        uiType: 'text',
        isMandatory: false,
        options: [
          { id: 1, option: 'Reddish' },
          { id: 2, option: 'Pinkish' },
          { id: 3, option: 'Whitish' },
        ],
      },
      {
        id: 2,
        name: 'nutrient',
        displayName: 'Nutrient content',
        uiType: 'multiSelect',
        isMandatory: false,
        options: [
          { id: 4, option: 'Carbs' },
          { id: 5, option: 'Protein' },
          { id: 6, option: 'Vitamins' },
          { id: 7, option: 'Micro Nutrients' },
        ],
      },
    ],
    skuAttributes: [
      {
        id: 4,
        name: 'netWeight',
        displayName: 'Net Weight',
        uiType: 'text',
        isMandatory: true,
      },
      {
        id: 5,
        name: 'grossWeight',
        displayName: 'Gross Weight',
        uiType: 'text',
        isMandatory: true,
      },
      {
        id: 3,
        name: 'shelfLife',
        displayName: 'Shelf Life',
        uiType: 'singleSelect',
        isMandatory: false,
        options: [
          { id: 8, option: '3 days' },
          { id: 9, option: '7 days' },
          { id: 10, option: '2 weeks' },
        ],
      },
      {
        id: 2121,
        name: 'nutrient',
        displayName: 'Nutrient content',
        uiType: 'multiSelect',
        isMandatory: false,
        options: [
          { id: 4, option: 'Carbs' },
          { id: 5, option: 'Protein' },
          { id: 6, option: 'Vitamins' },
          { id: 7, option: 'Micro Nutrients' },
        ],
      },
    ],
  },
});

export const postImageUploadResponse = () => ({
  action: 'success',
  data: {
    'rc-upload-1586537483683-2.png': {
      uuid: 'b59e00c8-e653-4644-95fc-46878961f4a3',
      path: 'products/202004/',
      ext: 'png',
      url:
        'https://s3.ap-south-1.amazonaws.com/hb-dev-images/products/202004/bd1c1fb9-a4e0-45b6-85e4-cf86155f4085.jpg',
    },
    'rc-upload-1586537483683-1.png': {
      uuid: 'b59e00c8-e653-4644-95fc-46878961f4a1',
      path: 'products/202004/',
      ext: 'png',
      url:
        'https://s3.ap-south-1.amazonaws.com/hb-dev-images/products/202004/bd1c1fb9-a4e0-45b6-85e4-cf86155f4085.jpg',
    },
  },
});

const fileUploadFile = {
  uid: 'rc-upload-1586537652063-2',
  lastModified: 1582636940995,
  lastModifiedDate: '2020-02-25T13:22:20.995Z',
  name: 'Screenshot_1582636940.png',
  size: 23031,
  type: 'image/png',
  percent: 0,
  originFileObj: { uid: 'rc-upload-1586537652063-2' },
  status: 'uploading',
};
