import React from 'react';
import {
  createUserApi,
  getAuthUserByIdApi,
  getAuthUsersApi,
  getRolesApi,
  putAuthUsersApi,
  deleteHbUserApi,
  getUserDetailsById,
  updateHbUser,
  putResetUserPasswordApi,
  putResetPasswordAllApi,
} from '../../redux/feature/auth/auth.api';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Tag,
  Space,
  Spin,
  Table,
  Col,
  Typography,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AuthService from '../../util/AuthService';
import { authSet } from './auth.routes';
import {
  getAdc,
  getDepartments,
} from '../../redux/feature/master-data/master.api';
import { filter, sortByName, getColumnSearchProps } from '../../util/Filters';
import { STATUS_FILTER_ARRAY } from '../../util/ConstantsService';

const { isAuthorisedUser } = AuthService;

const UsersList = props => {
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState(null);
  const [allRoles, setAllRoles] = React.useState(null);
  const [adcs, setAdcs] = React.useState(null);
  const [dept, setDept] = React.useState(null);
  const [showUserRolesModal, setUserRolesModal] = React.useState(false);
  const [formRef] = Form.useForm();
  const [user, setUser] = React.useState(null);
  const [userFormRef] = Form.useForm();
  const [showNewUserModal, setNewUserModal] = React.useState(false);
  const [editUserDetails, setEditUserDetails] = React.useState({
    hbUserId: null,
    iseditUser: false,
  });

  const FORM_LAYOUT = { labelCol: { span: 6 }, wrapperCol: { span: 16 } };
  const resetPassword = hbUserId => {
    setLoading(true);
    let api = putResetPasswordAllApi;
    if (hbUserId) api = putResetUserPasswordApi;
    api(hbUserId)
      .then(resp => {
        notification.success({
          message: 'Reset Password Successfully',
        });
        setLoading(false);
      })
      .catch(error => {
        console.log('Err @putResetUserPasswordApi: ', error);
        setLoading(false);
        notification.error({
          message: 'Reset password failed!',
        });
      });
  };
  const columns = [
    {
      title: 'User Id',
      dataIndex: 'hbUserId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      ...sortByName({ filterKey: 'name' }),
      ...getColumnSearchProps({
        dataIndex: 'name',
        label: 'User Name',
      }),
    },
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Department',
      dataIndex: 'department',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: text => text.join(', '),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ...filter({
        filterKey: 'status',
        filterArray: STATUS_FILTER_ARRAY,
      }),
      render: text =>
        !!text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="volcano">Inactive</Tag>
        ),
    },
    {
      title: 'Action',
      render: (text, record) =>
        record.status && (
          <Space>
            {isAuthorisedUser(authSet.EDIT_USER_ROLES) && (
              <Button
                type="ghost"
                onClick={() => {
                  setLoading(true);
                  const promises = [
                    getAuthUserByIdApi({ userId: record.hbUserId }),
                  ];
                  if (!allRoles) promises.push(getRolesApi());
                  Promise.all(promises)
                    .then(([userRoleResp, allRolesResp]) => {
                      const user = {
                        roleId: userRoleResp?.data?.roleId,
                        userId: record.hbUserId,
                        name: record.name,
                        username: record.username,
                      };
                      if (!allRoles) {
                        const roles = allRolesResp.data.map(r => ({
                          roleId: r.roleId,
                          roleName: r.roleName,
                        }));
                        setAllRoles(roles);
                      }
                      setUser(user);
                      formRef.resetFields();
                      formRef.setFieldsValue(user);
                      setUserRolesModal(true);
                      setLoading(false);
                    })
                    .catch(e => {
                      console.log('Err @getUserRoleMap: ', e);
                      setLoading(false);
                      notification.error({
                        message: 'User details not found.',
                      });
                    });
                }}
              >
                Edit Roles
              </Button>
            )}
            {isAuthorisedUser(authSet.EDIT_USER_ROLES) && (
              <Button
                onClick={() => {
                  setLoading(true);
                  setEditUserDetails({
                    hbUserId: record.hbUserId,
                    iseditUser: true,
                  });
                  const promises = [getUserDetailsById(record.hbUserId)];
                  if (!adcs) {
                    promises.push(getAdc());
                    promises.push(getDepartments());
                  }
                  // if (!allRoles) promises.push(getRolesApi());
                  Promise.all(promises)
                    .then(([hbUserIdResp, adcResp, deptResp]) => {
                      if (!adcs) {
                        setAdcs(adcResp.data);
                        setDept(deptResp.data);
                      }
                      const { data } = hbUserIdResp;
                      userFormRef.resetFields();
                      userFormRef.setFieldsValue(data);
                      setNewUserModal(true);
                      setLoading(false);
                      setEditUserDetails({
                        hbUserId: record.hbUserId,
                        iseditUser: true,
                      });
                    })
                    .catch(e => {
                      console.log('Err @Updating User: ', e);
                      notification.error({
                        message: 'Unable to Update user. ',
                      });
                      setLoading(false);
                      setEditUserDetails({
                        hbUserId: null,
                        iseditUser: false,
                      });
                    });
                }}
              >
                Edit Details
              </Button>
            )}

            {isAuthorisedUser(authSet.EDIT_USER_ROLES) && (
              <Button
                type="danger"
                onClick={() => {
                  Modal.confirm({
                    title: `Do you want to delete these User ${record.name} ?`,
                    icon: (
                      <ExclamationCircleOutlined style={{ marginTop: 6 }} />
                    ),

                    onOk() {
                      setLoading(true);
                      deleteHbUserApi(record.hbUserId)
                        .then(resp => {
                          notification.success({
                            message: 'User Deleted Successfully',
                          });
                          getUserRoles();
                        })
                        .catch(error => {
                          console.log('in catch', error);
                          setLoading(false);
                          notification.error({
                            message: 'User Deleted Successfully',
                          });
                        });
                    },
                    onCancel() {},
                  });
                }}
              >
                Delete
              </Button>
            )}
            {isAuthorisedUser(authSet.RESET_PASSWORD) && (
              <Button
                type="ghost"
                danger
                onClick={() => {
                  Modal.confirm({
                    title: `Do you want to reset password for ${record.name} ?`,
                    icon: (
                      <ExclamationCircleOutlined style={{ marginTop: 6 }} />
                    ),
                    okText: 'Reset Password',
                    okButtonProps: { type: 'ghost', danger: true },
                    onOk: () => resetPassword(record.hbUserId),
                    onCancel() {},
                  });
                }}
              >
                Reset Password
              </Button>
            )}
          </Space>
        ),
    },
  ];

  const getUserRoles = () => {
    getAuthUsersApi()
      .then(resp => {
        setUsers(resp.data);
        setLoading(false);
      })
      .catch(e => {
        notification.error({
          message: 'Cannot list users. Please try again later.',
        });
        setLoading(false);
      });
  };
  React.useEffect(getUserRoles, []);

  const setUserRoles = values => {
    setLoading(true);
    putAuthUsersApi(values)
      .then(resp => {
        getUserRoles();
        setUserRolesModal(false);
        notification.success({
          message: `User roles updated for ${user.name}`,
        });
      })
      .catch(e => {
        console.log('Err @setUserRoles: ', e);
        setLoading(false);
        notification.error({ message: 'Error updating user roles. ' });
      });
  };

  const createNewUser = values => {
    setLoading(true);
    if (editUserDetails.iseditUser) {
      updateHbUser(editUserDetails.hbUserId, values)
        .then(resp => {
          notification.success({ message: 'User Details Updated' });

          setLoading(false);
          setNewUserModal(false);
          setEditUserDetails({
            hbUserId: null,
            iseditUser: false,
          });
          getUserRoles();
        })
        .catch(e => {
          console.log('Err @updateHbUser: ', e);
          const message =
            e?.data?.description || 'Fail to update user details.';
          notification.error({ message });
          setLoading(false);
        });
    } else {
      const role = allRoles.find(r => r.roleId === values.roleId)?.roleName;
      if (role) values.role = role;
      createUserApi(values)
        .then(resp => {
          notification.success({ message: 'New user created.' });
          getUserRoles();
          setNewUserModal(false);
        })
        .catch(e => {
          console.log('Err @createNewUser: ', e);
          const message = e?.data?.description || 'Fail to create user.';
          notification.error({ message });
          setLoading(false);
        });
    }
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: 'Email is not a valid email!',
    },
  };

  return (
    <div>
      <Typography.Title level={3}>Users</Typography.Title>
      <Spin spinning={loading}>
        <Space>
          <Button
            type="primary"
            style={{ margin: '16px 0' }}
            onClick={() => {
              setLoading(true);
              let promises = [];
              if (!adcs) {
                promises.push(getAdc());
                promises.push(getDepartments());
              }
              if (!allRoles) promises.push(getRolesApi());
              Promise.all(promises)
                .then(([adcResp, deptResp, allRolesResp]) => {
                  if (!adcs) {
                    setAdcs(adcResp.data);
                    setDept(deptResp.data);
                  }
                  if (!allRoles) {
                    const roles = allRolesResp.data.map(r => ({
                      roleId: r.roleId,
                      roleName: r.roleName,
                    }));
                    setAllRoles(roles);
                  }
                  userFormRef.resetFields();
                  userFormRef.setFieldsValue({ password: 'password' });
                  setNewUserModal(true);
                  setLoading(false);
                })
                .catch(e => {
                  console.log('Err @Adding new user: ', e);
                  notification.error({ message: 'Unable to add new user. ' });
                  setLoading(false);
                });
            }}
          >
            Add new user
          </Button>
          {isAuthorisedUser(authSet.RESET_PASSWORD) && (
            <Button
              type="ghost"
              danger
              onClick={() => {
                Modal.confirm({
                  title: `Do you want to reset password for ALL USERS?`,
                  icon: <ExclamationCircleOutlined style={{ marginTop: 6 }} />,
                  okText: 'Reset Password For All Users',
                  okButtonProps: { type: 'ghost', danger: true },
                  onOk: () => resetPassword(),
                  onCancel() {},
                });
              }}
            >
              Reset Password For All Users
            </Button>
          )}
        </Space>
        <Table
          columns={columns}
          dataSource={users}
          loading={loading}
          rowKey="hbUserId"
          scroll={{ x: 800 }}
        />
      </Spin>
      {user && showUserRolesModal && (
        <Modal
          destroyOnClose={true}
          onOk={() => formRef.submit()}
          onCancel={() => {
            setUserRolesModal(false);
          }}
          visible={showUserRolesModal}
          title={`Set roles for ${user.name} (${user.username})`}
          confirmLoading={loading}
          okText="Set Roles"
        >
          <Form form={formRef} layout="vertical" onFinish={setUserRoles}>
            <Form.Item noStyle name="userId">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="roleId" label="Roles:">
              <Checkbox.Group>
                {allRoles?.map(r => (
                  <Col key={r.roleId}>
                    <Checkbox
                      style={{ padding: '8px 0', width: '100%' }}
                      value={r.roleId}
                      key={r.roleId}
                    >
                      {r.roleName}
                    </Checkbox>
                  </Col>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {showNewUserModal && (
        <Modal
          destroyOnClose={true}
          onOk={() => userFormRef.submit()}
          onCancel={() => {
            setNewUserModal(false);
          }}
          visible={showNewUserModal}
          title={editUserDetails?.iseditUser ? 'Edit User' : 'Create new user'}
          confirmLoading={loading}
          okText={editUserDetails?.iseditUser ? 'Save' : 'Create'}
        >
          <Form
            form={userFormRef}
            {...FORM_LAYOUT}
            onFinish={createNewUser}
            validateMessages={validateMessages}
          >
            <Form.Item
              name="email"
              label="Email Id"
              rules={[{ required: true }, { type: 'email' }]}
            >
              <Input />
            </Form.Item>
            {/*{!editUserDetails.iseditUser && (*/}
            {/*  <Form.Item*/}
            {/*    name="password"*/}
            {/*    label="Password"*/}
            {/*    rules={[{ required: true }]}*/}
            {/*  >*/}
            {/*    <Input disabled />*/}
            {/*  </Form.Item>*/}
            {/*)}*/}

            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="middleName" label="Middle Name">
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
            <Form.Item name="city" label="City">
              <Input />
            </Form.Item>
            <Form.Item name="state" label="State">
              <Input />
            </Form.Item>
            <Form.Item name="country" label="Country">
              <Input />
            </Form.Item>
            <Form.Item
              name="phoneNo"
              label="Phone No"
              rules={[
                {
                  required: true,
                  min: 10,
                  max: 10,
                  message: 'Phone number should be 10 digit',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="adcCode" label="ADC">
              <Select>
                {adcs.map(adc => (
                  <Select.Option
                    value={adc.adcCode}
                    key={adc.adcCode}
                  >{`${adc.adcCode}: ${adc.address}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="department"
              label="Department"
              rules={[{ required: true }]}
            >
              <Select>
                {dept.map(d => (
                  <Select.Option value={d.deptName} key={d.deptId}>
                    {d.deptName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {!editUserDetails.iseditUser && (
              <Form.Item name="roleId" label="Role">
                <Select>
                  {allRoles.map(r => (
                    <Select.Option value={r.roleId} key={r.roleId}>
                      {r.roleName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default UsersList;
