import AuthService from '../../util/AuthService';
import { NewTripAlert } from './NewTripAlert';
import ActiveTrip from './ActiveTrip';
import PastTrip from './PastTrip';
import Profile from './Profile';
import Secrets from './Secrets';
const { MENU_SET, MENU_SECTIONS, MENU_AUTH_CODE } = AuthService;

export const authSet = {
  VIEW_LOGISTIC_TRACKING: {
    menu: MENU_SET.CRM,
    section: MENU_SECTIONS.LOGISTIC_TRACKING,
    authority: MENU_AUTH_CODE.VIEW_LOGISTIC_TRACKING,
  },
};

export const DeliveryBoxRoutes = [
  {
    path: '/new-trip-alert/:tripId',
    component: NewTripAlert,
    title: 'New Trip Alert',
    isPrivate: false,
    auth: false,
  },
  {
    path: '/active-trips',
    component: ActiveTrip,
    title: 'Active Trips',
    isPrivate: false,
    auth: false,
  },
  {
    path: '/past-trips',
    component: PastTrip,
    title: 'Past Trips',
    isPrivate: false,
    auth: false,
  },
  {
    path: '/my-profile',
    component: Profile,
    title: 'My Profile',
    isPrivate: false,
    auth: false,
  },
  {
    path: '/secrets',
    component: Secrets,
    title: 'Secrets',
    isPrivate: false,
    auth: false,
  },
];
