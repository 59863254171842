import React from 'react';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

/**
 * getColumnSearchProps = apply search on given column name
 * @param { dataIndex, label} ("dataIndex" = key to search element , "label" = input box label )
 * @returns list of search result
 */

export const getColumnSearchProps = ({ dataIndex, label }) => {
  let searchInput = null;
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${label}`}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
  };
};

/**
 * filter = Filter the table by given key provided
 * @param {filterKey, filterArray}  ("filterKey"= filter the element and "filterArray"= for traverse the array )
 * @returns filtration list
 */

export const filter = ({ filterKey, filterArray }) => ({
  filters: filterArray,
  onFilter: (value, record) => record[filterKey] === value,
});

/**
 * sortByName = Sort the name by ascending and descending order
 * @param {filterKey}  ("filterKey"= to apply sorting)
 * @returns sorting list
 */

export const sortByName = ({ filterKey }) => {
  return {
    sorter: (a, b) => {
      if (a[filterKey] && b[filterKey])
        return a[filterKey].localeCompare(b[filterKey]);
      else return [];
    },
  };
};

/**
 * numericSort = Sort the key in a numeric way by ascending and descending order
 * @param {filterKey}  ("filterKey"= to apply sorting)
 * @returns sorting list
 */
export const numericSort = ({ filterKey }) => {
  return {
    sorter: (a, b) => {
      const aVal = a[filterKey] || 0,
        bVal = b[filterKey] || 0;
      return aVal - bVal;
    },
  };
};
