import React from 'react';
import {
  getMenusApi,
  getMenuSectionsApi,
  postMenuSectionsApi,
  putMenuSectionsApi,
} from '../../redux/feature/auth/auth.api';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Table,
} from 'antd';
import AuthService from '../../util/AuthService';
import { authSet } from '../../feature/auth/auth.routes';
const { isAuthorisedUser } = AuthService;

const MenuSectionList = props => {
  const [loading, setLoading] = React.useState(true);
  const [menus, setMenus] = React.useState(null);
  const [menuSections, setMenuSections] = React.useState(null);
  const [showNewSectionModal, setNewSectionModal] = React.useState(false);
  const [isNewMenuSection, setIsNewSection] = React.useState(false);
  const [formRef] = Form.useForm();

  const columns = [
    {
      title: 'Menu Section Id',
      dataIndex: 'menuSectionId',
    },
    {
      title: 'Menu',
      dataIndex: 'menuName',
    },
    {
      title: 'Menu Section',
      dataIndex: 'sectionCode',
    },
    {
      title: 'Action',
      render: (text, record) => (
        <Space>
          {isAuthorisedUser(authSet.EDIT_AUTH_CODES) && (
            <Button
              type="ghost"
              onClick={() => {
                setIsNewSection(false);
                setNewSectionModal(true);
                formRef.resetFields();
                formRef.setFieldsValue(record);
              }}
            >
              Edit Section
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const getMenuSections = () => {
    getMenuSectionsApi()
      .then(resp => {
        setMenuSections(resp.data);
        setLoading(false);
      })
      .catch(e => {
        console.log('Err @getMenuSections: ', e);
        setLoading(false);
        notification.error({ message: 'Error loading menus.' });
      });
  };

  const getMenus = () => {
    getMenusApi()
      .then(resp => {
        setMenus(resp.data);
        setLoading(false);
      })
      .catch(e => {
        console.log('Err @getMenus: ', e);
        setLoading(false);
        notification.error({ message: 'Error loading menus.' });
      });
  };

  React.useEffect(() => {
    getMenuSections();
    getMenus();
  }, []);

  const saveMenuSection = values => {
    setLoading(true);
    let api = postMenuSectionsApi;
    if (values.menuSectionId) api = putMenuSectionsApi;
    api(values)
      .then(resp => {
        getMenuSections();
        setNewSectionModal(false);
        notification.success({
          message: isNewMenuSection
            ? 'New menu section created.'
            : 'Menu section update',
        });
      })
      .catch(e => {
        console.log('Err @saveMenu: ', e);
        notification.error({ message: 'Error saving menu.' });
        setLoading(false);
      });
  };

  return (
    <div>
      {isAuthorisedUser(authSet.ADD_AUTH_CODES) && (
        <Button
          type="primary"
          style={{ margin: '16px 0' }}
          onClick={() => {
            setIsNewSection(true);
            setNewSectionModal(true);
            formRef.resetFields();
          }}
        >
          Add New Menu Section
        </Button>
      )}
      <Table
        columns={columns}
        dataSource={menuSections}
        loading={loading}
        rowKey="menuSectionId"
        scroll={{ x: 800 }}
        bordered
        size="small"
      />
      <Modal
        destroyOnClose={true}
        onOk={() => formRef.submit()}
        onCancel={() => {
          setNewSectionModal(false);
        }}
        visible={showNewSectionModal}
        title={isNewMenuSection ? 'Add new Menu Section' : 'Edit Menu Section'}
        confirmLoading={loading}
        okText={isNewMenuSection ? 'Add' : 'Update'}
      >
        <Form form={formRef} layout="vertical" onFinish={saveMenuSection}>
          <Form.Item noStyle name="menuSectionId">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="menuId" label="Menu:" rules={[{ required: true }]}>
            <Select>
              {menus?.map(menu => (
                <Select.Option value={menu.menuId}>
                  {menu.menuCode}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="sectionCode"
            label="Menu Section:"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MenuSectionList;
