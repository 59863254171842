import React from 'react';

const DashboardComponent = props => {
  return (
    <h2>
      This is your dashboard. This will be beautiful and meaningful in future.
    </h2>
  );
};

export default DashboardComponent;
