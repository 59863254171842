import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router';
import AuthService from '../util/AuthService';
const { isAuthorisedUser } = AuthService;

class PrivateRoute extends React.PureComponent {
  isValidUser = () => {
    const { initialCheckDone, isLoggedIn, auth } = this.props;
    if (auth) return isAuthorisedUser(auth);
    return initialCheckDone && isLoggedIn;
  };

  render() {
    // console.log('Rendering PrivateRoute');
    const { children, isLoggedIn, ...rest } = this.props;
    if (this.isValidUser()) {
      return <Route {...rest} />;
    } else if (isLoggedIn) {
      return <Redirect to={{ pathname: '/unauthorised' }} />;
    } else {
      return <Redirect to={{ pathname: '/' }} />;
    }
  }
}

const mapStateToProps = ({ initialReducer }) => {
  const { initialCheckDone, isLoggedIn, currentUser } = initialReducer;
  return {
    initialCheckDone,
    isLoggedIn,
    currentUser,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
