import React from 'react';
import { Tabs, Typography } from 'antd';
import MenuList from '../../components/Auth/MenuList';
import MenuSectionList from '../../components/Auth/MenuSectionList';
import AuthCodeList from '../../components/Auth/AuthCodeList';
import { authSet } from './auth.routes';
import AuthService from '../../util/AuthService';
import { Link } from 'react-router-dom';
const { isAuthorisedUser } = AuthService;

const AuthCodes = props => {
  const viewRolesLink = () => {
    if (isAuthorisedUser(authSet.VIEW_ROLES)) {
      return (
        <Link type="default" to={'/roles'} style={{ margin: '16px 0' }}>
          View Roles
        </Link>
      );
    } else {
      return false;
    }
  };

  return (
    <div>
      <Typography.Title level={3}>Authority Codes</Typography.Title>
      <div className="auth-codes">
        <Tabs type="card" tabBarExtraContent={viewRolesLink()}>
          <Tabs.TabPane key="menu" tab="Menu">
            <MenuList />
          </Tabs.TabPane>
          <Tabs.TabPane key="menu-section" tab="Menu Section">
            <MenuSectionList />
          </Tabs.TabPane>
          <Tabs.TabPane key="auth-code" tab="Authority Code">
            <AuthCodeList />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AuthCodes;
