import React, { useEffect, useState } from 'react';
import { Drawer, Button, Dropdown, Input, Menu } from 'antd';
import {
  DownOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import './upload-wrapper.scss';
import { Header } from 'antd/lib/layout/layout';
import { CRM_MENU, PU_MENU } from './SideNav';
import AuthService from '../util/AuthService';
import { Link } from 'react-router-dom';
import Logout from '../feature/login/Logout';

const {
  MENU_SET,
  MENU_SECTIONS,
  MENU_AUTH_CODE,
  isAuthorisedUser,
  getAuthCodes,
} = AuthService;

const roleBoundMenu = (menu = []) => {
  const roleMenu = (menu || [])?.filter(m => {
    if (!m.auth) return false;
    if (m?.auth) return true;
    return isAuthorisedUser(m?.auth);
    // debugger
    // if (props.role === 'SUPER_ADMIN') return true;
    // return m.roles.indexOf(props.role) > -1;
  });
  return roleMenu;
};
const NavBar = ({
  initialCheckDone = false,
  isLoggedIn = false,
  userFullName = '',
  email = '',
  role,
  profileMenu = [],
}) => {
  const [visible, setVisible] = useState(false);
  const [crmRoleBoundMenu, setCrmRoleBoundMenu] = useState([]);
  const [puRoleBoundMenu, setPuRoleBoundMenu] = useState([]);

  const resetInitialState = () => {
    setCrmRoleBoundMenu(CRM_MENU);
    setPuRoleBoundMenu(roleBoundMenu(PU_MENU));
  };
  useEffect(() => {
    resetInitialState();
  }, [isLoggedIn]);

  // if (!isLoggedIn) return null;

  const onSearch = event => {
    let filteredCrmMenu = roleBoundMenu(CRM_MENU);
    filteredCrmMenu = filteredCrmMenu.filter(
      p =>
        p.displayText.toLowerCase().indexOf(event.target.value.toLowerCase()) >
        -1
    );

    let filteredPuMenu = roleBoundMenu(PU_MENU);
    filteredPuMenu = filteredPuMenu.filter(
      p =>
        p.displayText.toLowerCase().indexOf(event.target.value.toLowerCase()) >
        -1
    );

    setCrmRoleBoundMenu(filteredCrmMenu);
    setPuRoleBoundMenu(filteredPuMenu);
  };
  // console.log('CRM Role Menu: ', crmRoleBoundMenu);
  return (
    <nav className="navbar">
      {isLoggedIn && (
        <Button
          className="menu"
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => setVisible(true)}
        />
      )}
      <Link className="logotext" to="/">
        <img
          src="./assets/app-icons/full_width_logo.png"
          alt="DeliveryBox Logo"
        />
      </Link>
      {initialCheckDone && isLoggedIn && (
        <Drawer
          title="Menu"
          placement="left"
          onClick={() => setVisible(false)}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <>
            {/* <div style={{ padding: 10 }}>
            <Input.Search
              placeholder="Search  Menu here"
              enterButton
              onChange={onSearch}
            />
          </div> */}
            <Menu
              mode="inline"
              defaultOpenKeys={['CRM']}
              style={{
                height: 'calc(100vh - 64px)',
              }}
            >
              {crmRoleBoundMenu?.length > 0 && (
                <Menu.SubMenu
                  key="CRM"
                  title={
                    <span>
                      <MailOutlined />
                      <span>Menu</span>
                    </span>
                  }
                >
                  {crmRoleBoundMenu.map(menu => (
                    <Menu.Item key={menu.key}>
                      <Link to={menu.linkTo}>
                        <menu.icon />
                        <span className="nav-text">{menu.displayText}</span>
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )}
              {puRoleBoundMenu?.length > 0 && (
                <Menu.SubMenu
                  key="PU"
                  title={
                    <span>
                      <MailOutlined />
                      <span>PU Menu</span>
                    </span>
                  }
                >
                  {puRoleBoundMenu.map(menu => (
                    <Menu.Item key={menu.key}>
                      <Link to={menu.linkTo}>
                        <menu.icon />
                        <span className="nav-text">{menu.displayText}</span>
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )}
              <Logout />
            </Menu>
          </>
        </Drawer>
      )}

      {/*<div>*/}
      {/*  {initialCheckDone && isLoggedIn && (*/}
      {/*    <div>*/}
      {/*      <Dropdown overlay={profileMenu}>*/}
      {/*        <Button className="logged-in-user">*/}
      {/*          {userFullName} ({email}) <DownOutlined />*/}
      {/*        </Button>*/}
      {/*      </Dropdown>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
    </nav>
  );
};

export default NavBar;
