import React, { useState, useEffect } from 'react';
import { Layout, Menu, Input, Button } from 'antd';
import { Link } from 'react-router-dom';

import Logout from '../feature/login/Logout';
import AuthService from '../util/AuthService';

import {
  AppstoreOutlined,
  AppstoreAddOutlined,
  DashboardOutlined,
  HomeOutlined,
  InboxOutlined,
  MailOutlined,
  // OrderedListOutlined,
  // PercentageOutlined,
  // PictureOutlined,
  // QrcodeOutlined,
  // ScanOutlined,
  // UploadOutlined,
  // UserOutlined,
  // UserSwitchOutlined,
  // BoldOutlined,
  // UsergroupAddOutlined,
  // FilePptOutlined,
  // FontColorsOutlined,
  // CommentOutlined,
  // ReloadOutlined,
  // GiftOutlined,
  // BarChartOutlined,
  // CarOutlined,
  // DatabaseOutlined,
} from '@ant-design/icons';
const { Sider } = Layout;

const {
  MENU_SET,
  MENU_SECTIONS,
  MENU_AUTH_CODE,
  isAuthorisedUser,
} = AuthService;

const ROLES = [
  { role: 'SUPER_ADMIN', desc: 'Can access everything' },
  { role: 'CRM_ADMIN', desc: 'Can access everything on CRM' },
  {
    role: 'CCA',
    desc:
      'Customer Care Associate who can create customer and perform order related activities on behalf of customers',
  },
  { role: 'PU_ADMIN', desc: 'Can access everything on PU' },
  { role: 'ADC_SUPERVISER_ADMIN', desc: 'Can access everything on ADC' },
  {
    role: 'ADC_ADMIN',
    desc: 'Can access everything on ADC except closing of invoice processing',
  },
];

export const CRM_MENU = [
  {
    linkTo: '/dashboard',
    icon: DashboardOutlined,
    displayText: 'Dashboard',
    key: 'dashboard',
    roles: ['SUPER_ADMIN', 'CRM_ADMIN', 'CCA'],
    auth: {
      menu: MENU_SET.CRM,
      // section: MENU_SECTIONS.CRM_DASHBOARD,
      section: true,
      authority: true,
    },
  },
  {
    linkTo: '/active-trips',
    icon: DashboardOutlined,
    displayText: 'Active Trips',
    key: 'activeTrips',
    auth: {
      menu: MENU_SET.CRM,
      section: true,
      authority: true,
    },
  },
  {
    linkTo: '/past-trips',
    icon: DashboardOutlined,
    displayText: 'Past Trips',
    key: 'pastTrips',
    auth: {
      menu: MENU_SET.CRM,
      section: true,
      authority: true,
    },
  },
  {
    linkTo: '/my-profile',
    icon: DashboardOutlined,
    displayText: 'My Profile',
    key: 'profile',
    auth: {
      menu: MENU_SET.CRM,
      section: true,
      authority: true,
    },
  },
  // {
  //   linkTo: '/new-trip-alert/:trip-id',
  //   icon: DashboardOutlined,
  //   displayText: 'New Trip Alert',
  //   key: 'newTripAlert',
  //   roles: ['SUPER_ADMIN', 'CRM_ADMIN', 'CCA'],
  //   auth: {
  //     menu: MENU_SET.CRM,
  //     // section: MENU_SECTIONS.CRM_DASHBOARD,
  //     section: true,
  //     authority: true,
  //   },
  // },
];
export const PU_MENU = [];

const roleBoundMenu = menu => {
  const roleMenu = menu?.filter(m => {
    if (!m.auth) return false;
    if (m?.auth === true) return true;
    return isAuthorisedUser(m.auth);
    // debugger
    // if (props.role === 'SUPER_ADMIN') return true;
    // return m.roles.indexOf(props.role) > -1;
  });
  return roleMenu;
};

const SideNav = props => {
  const { isLoggedIn, authCodes } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [crmRoleBoundMenu, setCrmRoleBoundMenu] = useState([]);
  const [puRoleBoundMenu, setPuRoleBoundMenu] = useState([]);

  const resetInitialState = () => {
    setCrmRoleBoundMenu(CRM_MENU);
    setPuRoleBoundMenu(roleBoundMenu(PU_MENU));
  };

  useEffect(() => {
    resetInitialState();
  }, [isLoggedIn]);
  if (!isLoggedIn) return null;

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const onSearch = event => {
    let filteredCrmMenu = roleBoundMenu(CRM_MENU);
    filteredCrmMenu = filteredCrmMenu.filter(
      p =>
        p.displayText.toLowerCase().indexOf(event.target.value.toLowerCase()) >
        -1
    );

    let filteredPuMenu = roleBoundMenu(PU_MENU);
    filteredPuMenu = filteredPuMenu.filter(
      p =>
        p.displayText.toLowerCase().indexOf(event.target.value.toLowerCase()) >
        -1
    );

    setCrmRoleBoundMenu(filteredCrmMenu);
    setPuRoleBoundMenu(filteredPuMenu);
  };

  return (
    <div style={{ marginRight: collapsed ? 0 : 200 }}>
      <Sider
        // width={200}
        style={{
          background: '#fff',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          // bottom: 5,
          top: 82,
        }}
        // collapsible
        // collapsed={collapsed}
        collapsedWidth={0}
        onCollapse={onCollapse}
        breakpoint={'sm'}
      >
        <div style={{ padding: 10 }}>
          <Input.Search
            placeholder="Search  Menu here"
            enterButton
            onChange={onSearch}
          />
        </div>
        <Menu
          mode="inline"
          defaultOpenKeys={['CRM', 'PU']}
          style={{
            height: 'calc(100vh - 64px)',
          }}
        >
          {crmRoleBoundMenu?.length > 0 && (
            <Menu.SubMenu
              key="CRM"
              title={
                <span>
                  <MailOutlined />
                  <span>Menu</span>
                </span>
              }
            >
              {crmRoleBoundMenu.map(menu => (
                <Menu.Item key={menu.key}>
                  <Link to={menu.linkTo}>
                    <menu.icon />
                    <span className="nav-text">{menu.displayText}</span>
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          )}
          {puRoleBoundMenu?.length > 0 && (
            <Menu.SubMenu
              key="PU"
              title={
                <span>
                  <MailOutlined />
                  <span>PU Menu</span>
                </span>
              }
            >
              {puRoleBoundMenu.map(menu => (
                <Menu.Item key={menu.key}>
                  <Link to={menu.linkTo}>
                    <menu.icon />
                    <span className="nav-text">{menu.displayText}</span>
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          )}
          <Logout
            resetState={() => {
              setCollapsed(false);
            }}
          />
        </Menu>
      </Sider>
    </div>
  );
};

export default SideNav;
