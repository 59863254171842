import React from 'react';
import { Result } from 'antd';

const UnauthorizedAccess = props => {
  return (
    <Result
      status="403"
      title="Unauthorized Access"
      subTitle="Sorry, you are not authorized to access this page. Please contact your admin for more info."
    />
  );
};

export default UnauthorizedAccess;
