const prefix = 'CUSTOMER/';
export const GET_CUSTOMERS = `${prefix}GET_CUSTOMERS`;
export const SET_CUSTOMERS = `${prefix}SET_CUSTOMERS`;
export const MERGE_CUSTOMERS = `${prefix}MERGE_CUSTOMERS`;
export const GET_CUSTOMER_BY_ID = `${prefix}GET_CUSTOMER_BY_ID`;
export const SET_CUSTOMER_BY_ID = `${prefix}SET_CUSTOMER_BY_ID`;
export const RESET_CUSTOMER_DETAILS = `${prefix}RESET_CUSTOMER_DETAILS`;
export const UPSERT_CUSTOMER = `${prefix}UPSERT_PRODUCT`;
export const SEARCH_CUSTOMER = `${prefix}SEARCH_CUSTOMER`;
export const UPSERT_ADDRESS = `${prefix}UPSERT_ADDRESS`;
export const ORDER_FOR_CUSTOMER = `${prefix}ORDER_FOR_CUSTOMER`;
export const SET_ADDRESS_BY_ID = `${prefix}SET_ADDRESS_BY_ID`;
export const SET_LOADING_CUSTOMER = `${prefix}SET_LOADING_CUSTOMER`;

export const getCustomerById = data => {
  return {
    type: GET_CUSTOMER_BY_ID,
    data,
  };
};

export const getCustomers = data => ({ type: GET_CUSTOMERS, data });

export const upsertCustomer = data => {
  return {
    type: UPSERT_CUSTOMER,
    data,
  };
};

export const upsertAddress = data => {
  return {
    type: UPSERT_ADDRESS,
    data,
  };
};

export const searchCustomers = data => ({ type: SEARCH_CUSTOMER, data });

export const orderForCustomer = data => ({ type: ORDER_FOR_CUSTOMER, data });

export const resetCustomerDetails = () => ({ type: RESET_CUSTOMER_DETAILS });
