import request from '../../../util/request';
import {
  getAttributesBySubcategoryForProductResponse,
  getCategoriesForProductResponse,
} from '../../../util/mockApiService';

export const getCategoriesForProductApi = () =>
  request
    .get('/crm/categoryToCreateProduct')
    .then(resp => resp)
    .catch(e => getCategoriesForProductResponse());

export const getAttributesBySubcategoryForProductApi = subcategoryId =>
  request
    .get('/crm/attributesBySubcategoryForProduct/' + subcategoryId)
    .then(resp => resp)
    .catch(e => getAttributesBySubcategoryForProductResponse());

export const postProductApi = data => request.post('/crm/product', data);

export const putProductApi = data => request.put('/crm/product', data);

export const getAllProductsApi = () => request.get('/crm/products');

export const getProductByIdApi = productId =>
  request.get(`/crm/product/${productId}`);

export const getProductPriceDetailsApi = ({ productId, cityId }) =>
  request.get(
    `/crm/product/${productId}/pricingPreSaleAndAdcDetails/${cityId}`
  );

export const updateProductPriceDetailsApi = data =>
  request.put(
    `/crm/product/pricingPreSaleAndAdcDetails/${data.productId}`,
    data
  );

export const getAdcwiseProductDealsDetailsApi = ({ productId, adcCode }) =>
  request.get(`/crm/product/${productId}/deal/${adcCode}`);

export const putAdcwiseProductDealsDetailsApi = data =>
  request.put(`/crm/product/deal/${data.productId}`, data);

export const searchSkuApi = data => request.post('/crm/searchInventory', data);

export const getAllPresentAndFuturePresaleApi = ({ productId, cityId }) =>
  request.get(
    `/crm/product/${productId}/getAllPresentAndFuturePresale/${cityId}`
  );

export const newPresaleLedgerDetailsApi = data =>
  request.post('/crm/product/newPresaleLedgerDetails', data);

export const deletePresaleLedgerDetailsApi = productId =>
  request.del(`/crm/product/deletePresaleDate/${productId}`);

export const putToggleWeekendSplSkuApi = ({ status }) =>
  request.put(`/crm/product/setWeekendSpecialStatus?status=${status}`);

export const getWeekendSplStatus = () =>
  request.get('/crm/provisionalCategory/getWeekendSpecialStatus');
